import { Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InfoCircuit, MsTrans3Model } from '../../models/Index';
import TextFieldOptions from '../common/TextFieldOptions';
import { msTrans3GetDropPerUnit, msTrans3GetDropVoltDiff, msTrans3GetInrushValue, msTrans3GetIntensityValue, msTrans3GetLoadCapacity, msTrans3GetLoadCurrent, msTrans3GetRatedCurrent, msTransNormalCalculation } from '../../utils/PowerCalculation';
import { BeamsNumber } from '../../utils/FormatNumber';
import { MS_MODE_CHECKING, MS_XR_MAX, VOLT_SIDE_PRIMARY, VOLT_SIDE_SECONDARY } from '../../statics';
import * as Constant from "../../models/Constants";

export type MsTrans3EditDialogFormProps = {
    data: any;
    onOK: (data: any) => void;
    onCancel: () => void;
    setIsSourceVoltage:boolean;
    infoCircuit: InfoCircuit;
    userRole: string;
    trans3List: any;
    m_bModePM: boolean;
    modeViewOnly: boolean;
    dispInputDialog:any;
    processMode:number;
};
const checkboxStyle = {
    padding: '0px 0px 0px 9px'
}
const checkBoxStyles = { padding: '4px 4px 4px 8px' }


function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const radioStyles = {
    padding: '0 0 0 9px',
}

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

export const MsTrans3EditDialog = (props: MsTrans3EditDialogFormProps) => {
    const {m_bModePM, userRole, data, onOK, onCancel, setIsSourceVoltage, infoCircuit, trans3List, dispInputDialog, processMode, modeViewOnly } = props;

    // 初期値設定
    
    const initialValue: MsTrans3Model = { ...data,
        calcPoint0: {...data.calcPoint0,loadCapacity: BeamsNumber(data.calcPoint0.loadCapacity,7),
                    loadCurrent:BeamsNumber(data.calcPoint0.loadCurrent,5),
                    powerFactor:BeamsNumber(data.calcPoint0.powerFactor,5)},
        calcPoint1: {...data.calcPoint1,loadCapacity: BeamsNumber(data.calcPoint1.loadCapacity,7),
            loadCurrent:BeamsNumber(data.calcPoint1.loadCurrent,5),
            powerFactor:BeamsNumber(data.calcPoint1.powerFactor,5)},
        voltageDrop: BeamsNumber(data.voltageDrop,4) ,
        voltageDrop1: BeamsNumber(data.voltageDrop1,4),
        xr: BeamsNumber(data.xr,5),
        percentZ: BeamsNumber(data.percentZ,5),

    }
    
    const { control, handleSubmit, formState: { errors }, setValue, getValues,watch,clearErrors,setError } = useForm<MsTrans3Model>({
        mode: 'all',
        criteriaMode: 'all',
        defaultValues: initialValue,
    });

    const [selectTab, setSelectTab] = useState(0);
    const [dropRegular, setDropRegular] = useState<boolean>(initialValue.dropRegular);
    const [makeDataInput, setMakeDataInput] = useState(initialValue.makeDataInput); // ModePM
    

    // Submitイベント
    const handleEditSubmit = (value: MsTrans3Model) => {
        const request = createRequestData(value);
        onOK(request);
    }

    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: MsTrans3Model) => {
        if(!Number(formValue.voltageAdjust)){
            formValue.voltageAdjust = 0
        }
        if(!Number(formValue.xr)){
            formValue.xr = 0
        }
        if(!Number(formValue.percentR)){
            formValue.percentR = 0
        }
        if(!Number(formValue.percentX)){
            formValue.percentX = 0
        }
        let request = { ...formValue} as MsTrans3Model;
        return request;
    }

    // キャンセル
    const handleCancel = () => {
        onCancel();
    };
    
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        if (newValue >= 0) {
            if (getValues('refNo') == "" ||getValues('refNo') == null || getValues('refNo') == undefined ) {
                setError("refNo",{ type: 'custom', message: '必須項目です。入力してください。' });
            }
            else if (isNaN(Number(getValues('voltagePrimary'))) || Number(getValues('voltagePrimary')) <= 0) {
                setError("voltagePrimary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('voltageSecondary'))) || Number(getValues('voltageSecondary')) <= 0) {
                setError("voltageSecondary",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('capacity'))) || Number(getValues('capacity')) <= 0) {
                setError("capacity",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('xr'))) || Number(getValues('xr'))  < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('xr').toString())) {
                setError("xr",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentZ'))) || Number(getValues('percentZ')) <= 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentZ').toString())) {
                setError("percentZ",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentR'))) || Number(getValues('percentR')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentR').toString())) {
                setError("percentR",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('percentX'))) || Number(getValues('percentX')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('percentX').toString())) {
                setError("percentX",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('inrushTimes'))) || Number(getValues('inrushTimes')) <= 0) {
                setError("inrushTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('intensityTimes'))) || Number(getValues('intensityTimes')) <= 0) {
                setError("intensityTimes",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('dampingTime'))) || Number(getValues('dampingTime')) <= 0) {
                setError("dampingTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('intensityTime'))) || Number(getValues('intensityTime')) <= 0) {
                setError("intensityTime",{ type: 'custom', message: '数字 > 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.loadCurrent'))) || Number(getValues('calcPoint0.loadCurrent')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCurrent').toString())) {
                setError("calcPoint0.loadCurrent",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.loadCapacity'))) || Number(getValues('calcPoint0.loadCapacity')) < 0 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.loadCapacity').toString())) {
                setError("calcPoint0.loadCapacity",{ type: 'custom', message: '数字 >= 0 を入力してください。' });
            }
            else if (isNaN(Number(getValues('calcPoint0.powerFactor'))) || Number(getValues('calcPoint0.powerFactor')) < 0 || Number(getValues('calcPoint0.powerFactor')) > 1 || !/^[0-9]*[.]?[0-9]+$/.test(getValues('calcPoint0.powerFactor').toString())) {
                setError("calcPoint0.powerFactor",{ type: 'custom', message: '数字 0 ~ 1  を入力してください。' });
            }
            else{
                setSelectTab(newValue);

            }
        }
        else{
        setSelectTab(newValue);
        }
    };

    const handleChangeFormat = (e: any) => {
        setValue(e.target.name, e.target.value);
    }

    const handleChangeCheckbox = (e: any) => {
        setValue(e.target.name, e.target.checked);
    }

    // ModePM
    const handleChangeMakeDataInput = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setMakeDataInput(e.target.checked);
    }
    const doSetValueDropLoadCapacity =(voltage:number,nCalcKind:number,loadCurrent:number) =>{
        let loadCapacity = voltage * loadCurrent
        if(nCalcKind == Constant.CALC_IK3){
            loadCapacity *= Math.sqrt(3.0)
        }
        
        return BeamsNumber(loadCapacity,7)
    }
    const handleResetCurrentTopLeft = (e: any) => {
        clearErrors("calcPoint0.loadCurrent")
        clearErrors("calcPoint0.loadCapacity")

        setValue("inputedCapacity1",false);
        setValue("inputedCurrent1",false);
        setValue("calcPoint0.loadCurrent",BeamsNumber(dispInputDialog.load_current_0,5))
        setValue("calcPoint1.loadCurrent",BeamsNumber(dispInputDialog.load_current_0,5))

        setValue("calcPoint0.loadCapacity",doSetValueDropLoadCapacity(getValues("voltageSecondary"),getValues("calcPoint0.calcKind"),dispInputDialog.load_current_0))
        setValue("calcPoint1.loadCapacity",doSetValueDropLoadCapacity(getValues("voltageSecondary"),getValues("calcPoint1.calcKind"),dispInputDialog.load_current_0))
        doCalcVoltageDropValue();
    }

    const handleResetLeadLag = (e: any) => {
        clearErrors("calcPoint0.powerFactor")

        setValue("inputedPowerFactor1",false);

        setValue('calcPoint0.isLead', dispInputDialog.is_lead_0);
        setValue('calcPoint1.isLead', dispInputDialog.is_lead_0);

        setValue('calcPoint0.powerFactor', BeamsNumber(dispInputDialog.power_factor_0,5));
        setValue('calcPoint1.powerFactor', BeamsNumber(dispInputDialog.power_factor_0,5));

        doCalcVoltageDropValue();
    }
    const doSetVoltageTrans3 =(voltSide:number,voltage:number) =>{
        if(voltSide == VOLT_SIDE_SECONDARY){
            setValue("calcPoint0.voltage",voltage)
            setValue("calcPoint1.voltage",voltage)
        }
    }
    const handleChangeVoltagePrimary = (e: any) => {
        // setValue(e.target.name, e.target.value);
        if(e.target.value > 0)
            clearErrors(e.target.name)
        if(+getValues("voltAdjustPart") == VOLT_SIDE_PRIMARY){
            setValue("voltageAdjust",+e.target.value)
        }
        doSetValuesTrans3()
        let ratedCurrentParmary = msTrans3GetRatedCurrent(
            getValues('capacity'),
            e.target.value
        );
        setValue('ratedCurrentParmary', BeamsNumber(ratedCurrentParmary, 4));

        let inrushValue = msTrans3GetInrushValue(
            getValues('inrushTimes'),
            getValues('capacity'),
            e.target.value
        );
        setValue('inrushValue', BeamsNumber(inrushValue, 4));
    }

    const handleChangeVoltageSecondary = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0)
            clearErrors(e.target.name)
        doSetVoltageTrans3(VOLT_SIDE_SECONDARY,e.target.value)
        if(+getValues("voltAdjustPart") == VOLT_SIDE_SECONDARY){
            setValue("voltageAdjust",+e.target.value)
        }
        doCalcSetValues(+getValues("capacity"),"IDC_VOLTAGE_SECONDARY")
        doSetValuesTrans3()
        // setValue('voltageAdjust', e.target.value);

        // let ratedCurrentSecondary = msTrans3GetRatedCurrent(
        //     getValues('capacity'),
        //     e.target.value
        // );
        // setValue('ratedCurrentSecondary', BeamsNumber(ratedCurrentSecondary, 4));

        let intensityValue = msTrans3GetIntensityValue(
            getValues('intensityTimes'),
            getValues('capacity'),
            e.target.value
        );
        setValue('intensityValue', BeamsNumber(intensityValue, 4));

        doCalcVoltageDropValue();
        // doCalcPowerNormal();
    }
    const doReadByCapacityT3 = (capacity:number,perR:number,perX:number,inrushTimes:number,dampingTime:number)=>{
        const capacityList = trans3List
        if(capacityList !== undefined){
            for(const item of capacityList){
                if(capacity <= item.dcapacity || item.dcapacity === -1){
                    perR = item.dpercentR
                    perX = item.dpercentX
                    inrushTimes = item.dpeakValue
                    dampingTime =item.ddampingTime
                    break;
                }
            }
        } else {
            perX = initialValue.percentX
            perR = initialValue.percentR
        }
        return {
            perR: perR,
            perX: perX,
            inrushTimes: inrushTimes,
            dampingTime: dampingTime,
        }
    }
    const doGetValuePercentRe =(xr:number,percentZ:number ) =>{
        return percentZ / Math.sqrt(xr * xr +1)
    }
    const doGetValuePercentXe =(xr:number,percentR:number) =>{
        return xr * percentR
    }
    const doCalcSetValues = (capacity:number,nControl:string) =>{
        let nFrequency = infoCircuit.frequency
        let perR = 0
        let perX = 0
        let inrushTimes = 0
        let dampingTime = 0
        const doRead = doReadByCapacityT3(capacity,perR,perX,inrushTimes,dampingTime)
        perR = doRead.perR
        perX = doRead.perX
        inrushTimes = doRead.inrushTimes
        dampingTime = doRead.dampingTime
        // const xr = percentX /percentR
        const xr = getValues("xr")

        const percentZ = getValues("percentZ")
        if(capacity <= 0){
            setValue("percentZ",0)
            setValue("xr",0)
        } else {
            if(nControl !== "IDC_VOLTAGE_SECONDARY" && nControl !== "IDC_PERCENT_R"){
                if(+xr === 0){
                    setValue("percentR",percentZ)
                    setValue("percentX",0)
                }
                else if (xr === MS_XR_MAX){
                    setValue("percentR",0)
                    setValue("percentX",percentZ)
                }
                else{
                    const percentR = doGetValuePercentRe(+xr,percentZ)
                    setValue("percentR",BeamsNumber(percentR,5))
                }
            }
            if(nControl !== "IDC_VOLTAGE_SECONDARY" && nControl !== "IDC_PERCENT_X"){
                if(+xr != 0 && +xr !== MS_XR_MAX){
                    const percentX = doGetValuePercentXe(+xr,getValues("percentR"))
                    setValue("percentX",BeamsNumber(percentX,5))
                }
            }
            if(nControl !== "IDC_PERCENT_Z" && nControl !== "IDC_XR"){
                const percentZ = Math.sqrt(perR*perR + perX*perX)
                setValue("percentZ",BeamsNumber(percentZ,5))
                if(perR === 0){
                    setValue("xr",0)
                }
                else if(perX === 0){
                    setValue("xr",MS_XR_MAX)
                }
                else{
                    setValue("xr",BeamsNumber(perX/perR,5))
                }
            }
        }
        setValue("inrushTimes", BeamsNumber(inrushTimes,4))
        if (nFrequency === 0) {
            setValue("dampingTime",0)
        } else {
            setValue("dampingTime", dampingTime * 1000 / nFrequency)
        }
    }
    const doGetValueOhmR =(voltageSecondary:number,capacity:number,percentR:number) =>{
        if(capacity === 0 || percentR === 0){
            return 0
        }
        return voltageSecondary * voltageSecondary / capacity / 1000 * percentR * 10
    }
    const doGetValueOhmX = (voltageSecondary:number,capacity:number,percentX:number) =>{
        if(capacity === 0 || percentX === 0){
            return 0
        }
        return voltageSecondary * voltageSecondary / capacity / 1000 * percentX * 10
    }
    const doGetValueOhmZ = () =>{
        const ohmR = doGetValueOhmR(+getValues("voltageSecondary"),+getValues("capacity"),+getValues("percentR"))
        const ohmX = doGetValueOhmX(+getValues("voltageSecondary"),+getValues("capacity"),+getValues("percentX"))
        const ohmZ = Math.sqrt(ohmR * ohmR + ohmX * ohmX)
        return ohmZ
    }
    const doSetValuesTrans3 =() =>{
        let ratedCurrentParmary = msTrans3GetRatedCurrent(
            +getValues("capacity"),
            getValues('voltagePrimary')
        );
        setValue('ratedCurrentParmary', BeamsNumber(ratedCurrentParmary, 4));

        let ratedCurrentSecondary = msTrans3GetRatedCurrent(
            +getValues("capacity"),
            getValues('voltageSecondary')
        );
        setValue('ratedCurrentSecondary', BeamsNumber(ratedCurrentSecondary, 4));
        let ohmZ = doGetValueOhmZ()
        let ohmR = doGetValueOhmR(+getValues("voltageSecondary"),+getValues("capacity"),+getValues("percentR"))
        let ohmX = doGetValueOhmX(+getValues("voltageSecondary"),+getValues("capacity"),+getValues("percentX"))
        setValue("ohmZ",BeamsNumber(ohmZ,4))
        setValue("ohmR",BeamsNumber(ohmR,4))
        setValue("ohmX",BeamsNumber(ohmX,4))
    }
    const handleChangeCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        if(e.target.value > 0)
            clearErrors(e.target.name)
        doCalcSetValues(+e.target.value,"")
        doSetValuesTrans3()
        // let ratedCurrentParmary = msTrans3GetRatedCurrent(
        //     e.target.value,
        //     getValues('voltagePrimary')
        // );
        // setValue('ratedCurrentParmary', BeamsNumber(ratedCurrentParmary, 4));

        // let ratedCurrentSecondary = msTrans3GetRatedCurrent(
        //     e.target.value,
        //     getValues('voltageSecondary')
        // );
        // setValue('ratedCurrentSecondary', BeamsNumber(ratedCurrentSecondary, 4));

        let inrushValue = msTrans3GetInrushValue(
            getValues('inrushTimes'),
            e.target.value,
            getValues('voltagePrimary')
        );
        setValue('inrushValue', BeamsNumber(inrushValue, 4));

        let intensityValue = msTrans3GetIntensityValue(
            getValues('intensityTimes'),
            e.target.value,
            getValues('voltageSecondary')
        );
        setValue('intensityValue', BeamsNumber(intensityValue, 4));

        doCalcVoltageDropValue();
        // doCalcPowerNormal();
        clearErrorsGenerator()
    }
    const handleChangeInrushTimes = (e: any) => {
        setValue(e.target.name, e.target.value);
        let inrushValue = msTrans3GetInrushValue(
            e.target.value,
            getValues('capacity'),
            getValues('voltagePrimary')
        );
        setValue('inrushValue', BeamsNumber(inrushValue, 4));
    }
    
    const handleChangeIntensityTimes = (e: any) => {
        setValue(e.target.name, e.target.value);
        let intensityValue = msTrans3GetIntensityValue(
            e.target.value,
            getValues('capacity'),
            getValues('voltageSecondary')
        );

        setValue('intensityValue', BeamsNumber(intensityValue, 4));
    }

    
    const hanDleChangedropRegular = (e: any) => {
        setValue(e.target.name, e.target.checked);
        setDropRegular(e.target.checked);
    }
    const handleChangeLoadCapacity = (e: any) => {
        setValue(e.target.name, e.target.value);
        let loadCurrent = msTrans3GetLoadCurrent(
            getValues('voltageSecondary'),
            e.target.value
        );
        setValue('calcPoint0.loadCurrent', BeamsNumber(loadCurrent, 7));
        
        doCalcVoltageDropValue();
    }

    const handleChangeLoadCurrent = (e: any) => {
        setValue(e.target.name, e.target.value);

        let loadCapacity = msTrans3GetLoadCapacity(
            getValues('voltageSecondary'),
            e.target.value
        );
        setValue('calcPoint0.loadCapacity', BeamsNumber(loadCapacity, 7));

        doCalcVoltageDropValue();
    }

    const handleChangeFormat2 = (e: any) => {
        if (e.target.name === 'calcPoint0.isLead')
            setValue(e.target.name, e.target.value == 'true'); // RadioGroup bool true => string 'true' ??
        else 
            setValue(e.target.name, e.target.value);
        doCalcVoltageDropValue();
    }
    
    const handleChangePerXRZ = (e: any) => {
        setValue(e.target.name, e.target.value);
        let nControl = e.target.name =='xr'?'IDC_XR':'IDC_PERCENT_Z';
        doCalcSetValues(+getValues('capacity'),nControl)
        doSetValuesTrans3()
        doCalcPowerFromXRZ();
        clearErrorsGenerator()

    }

    const handleChangePerX = (e: any) => {
        setValue(e.target.name, e.target.value);
        const percentX = +e.target.value
        const percentR = +getValues("percentR")
        if(percentX=== 0){
            setValue("xr",0)
        }
        else if(percentR === 0){
            setValue("xr",MS_XR_MAX)
        }
        else{
            setValue("xr",BeamsNumber(percentX/percentR,5))
        }
        const percentZ = Math.sqrt(percentR *percentR + percentX * percentX)
        setValue("percentZ",BeamsNumber(percentZ,5))
        doSetValuesTrans3()
        // if (getValues('percentR') == 0 && e.target.value > 0)
        //     setValue('xr', MS_XR_MAX);
        // else 
        //     setValue('xr', BeamsNumber(e.target.value / getValues('percentR'), 5));

        doCalcVoltageDropValue();
        // doCalcPowerNormal();
        clearErrorsGenerator()
    }

    const handleChangePerR = (e: any) => {
        setValue(e.target.name, e.target.value);
        const percentR = +e.target.value
        const percentX = +getValues("percentX")
        if(percentR > 0){
            setValue("xr",BeamsNumber((percentX/percentR),5))
        }
        else{
            setValue("xr",0)
        }
        if(percentR === 0){
            setValue("xr",MS_XR_MAX)
        }
        const percentZ = Math.sqrt(percentR *percentR + percentX * percentX)
        setValue("percentZ",BeamsNumber(percentZ,5))
        doSetValuesTrans3()
        
        // if (e.target.value == 0 && getValues('percentX') > 0)
        //     setValue('xr', MS_XR_MAX);
        // else 
        //     setValue('xr', BeamsNumber(getValues('percentX') / e.target.value, 5));

        doCalcVoltageDropValue();
        // doCalcPowerNormal();
        clearErrorsGenerator()

    }

    const doCalcVoltageDropValue = () => {
        let calcData = {
            capacity: getValues('capacity'),
            voltageSecondary: getValues('voltageSecondary'),
            voltageAdjust: getValues('voltageAdjust'),
            percentR: getValues('percentR'),
            percentX: getValues('percentX'),
            powerFactor: getValues('calcPoint0.powerFactor'),
            loadCurrent: getValues('calcPoint0.loadCurrent'),
            leadLag: getValues('calcPoint0.isLead'),
        };
        let voltageDrop1 = msTrans3GetDropVoltDiff(calcData);
        let voltageDrop = msTrans3GetDropPerUnit(calcData);
        setValue('voltageDrop1', BeamsNumber(voltageDrop1, 4));
        setValue('voltageDrop', BeamsNumber(voltageDrop, 4));
    }

    const doCalcPowerNormal = () => {
        let calcData = {
            voltageSecondary: getValues('voltageSecondary'),
            capacity: getValues('capacity'),
            percentR: getValues('percentR'),
            percentX: getValues('percentX'),
        }
        let newPower = msTransNormalCalculation(calcData);
        
        setValue('percentZ', BeamsNumber(newPower.percentZ, 5));
        setValue('ohmZ', BeamsNumber(newPower.ohmZ, 4));
        setValue('ohmR', BeamsNumber(newPower.ohmR, 4));
        setValue('ohmX', BeamsNumber(newPower.ohmX, 4));
    }

    const doCalcPowerFromXRZ = () => {
        // let newPower = msTransFromXR_ZCalculation(
        //     getValues('voltageSecondary'),
        //     getValues('percentZ'),
        //     getValues('capacity'),
        //     getValues('xr')
        // );

        // if (newPower.percentR !== undefined)
        //     setValue('percentR', BeamsNumber(newPower.percentR, 5));
        // if (newPower.percentX !== undefined)
        //     setValue('percentX', BeamsNumber(newPower.percentX, 5));
        // setValue('ohmZ', BeamsNumber(newPower.ohmZ, 4));
        // setValue('ohmR', BeamsNumber(newPower.ohmR, 4));
        // setValue('ohmX', BeamsNumber(newPower.ohmX, 4));
        doCalcVoltageDropValue();

    }
    const clearErrorsGenerator =() =>{
        if(getValues('capacity') > 0){
            clearErrors("capacity")
        }
        if(getValues("xr") >= 0){
            clearErrors('xr')
        }
        if(getValues('percentZ') > 0){
            clearErrors('percentZ')
        }
        if(getValues('percentR') >= 0){
            clearErrors('percentR')
        }
        if(getValues('percentX') >= 0){
            clearErrors('percentX')
        }
    }
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={selectTab} onChange={handleChange}
                    variant="scrollable" 
                    scrollButtons 
                    allowScrollButtonsMobile 
                    sx={{
                        '& .MuiButtonBase-root.MuiTabScrollButton-root.Mui-disabled': {
                            display: "none"
                        }
                    }}
                >
                    <Tab label='基本' {...a11yProps(0)} />
                    <Tab label='保護協調' {...a11yProps(1)} />
                    <Tab label='電圧降下' {...a11yProps(2)} />
                    <Tab label='シンボル' {...a11yProps(3)} />
                </Tabs>
            </Box>
            <form onSubmit={handleSubmit(handleEditSubmit)}>
                <div style={{ height: '40vh', width: '100%', margin: 'auto', overflow: 'auto' }}>
                    {/* tab0 */}
                    <TabPanel value={selectTab} index={0}>
                        <Grid container mt={1}>
                            <Grid item xs={6}>
                                <Stack spacing={0.3}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">デバイス名:</Typography>
                                        <Controller
                                            name='refNo'
                                            control={control}
                                            rules={{
                                                required: '必須項目です。入力してください。',
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    size='small'
                                                    error={!!errors?.refNo}
                                                    helperText={errors?.refNo?.message}
                                                    onChange={handleChangeFormat}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">事故点名称:</Typography>
                                        <Controller
                                            name='pointText'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='text'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{readOnly: m_bModePM || modeViewOnly}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">一次側回路電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltagePrimary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        options={initialValue.voltagePrimaryList}
                                                        // type='number'
                                                        size='small'
                                                        setValue={(name: any, value: any) => {
                                                            field.onChange({target: {name: name, value: value}})
                                                            handleChangeVoltagePrimary({target: {name: name, value: value}});
                                                        }}
                                                        // onSelectValue={(name:any,value) => {
                                                        //     handleChangeVoltagePrimary({target: {name: name, value: value}})
                                                        // }}
                                                        height= {32}
                                                        maxLength={6}
                                                        disabled={m_bModePM || modeViewOnly || setIsSourceVoltage || processMode > MS_MODE_CHECKING}
                                                        error={!!errors?.voltagePrimary}
                                                        helperText={errors?.voltagePrimary?.message}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">二次側回路電圧(V):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='voltageSecondary'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。' ,
                                                    pattern:{
                                                        value: /^[0-9\b]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.voltageSecondaryList}
                                                        // type='number'
                                                        size='small'
                                                        onChange={handleChangeVoltageSecondary}
                                                        onSelectValue={(name:any,value) => {
                                                            handleChangeVoltageSecondary({target: {name: name, value: value}})
                                                        }}
                                                        maxLength={6}
                                                        error={!!errors?.voltageSecondary}
                                                        helperText={errors?.voltageSecondary?.message}
                                                        height= {32}
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">容量(kVA):</Typography>
                                        <FormControl fullWidth size='small'>
                                            <Controller
                                                name='capacity'
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }: any) => (
                                                    <TextFieldOptions
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                        options={initialValue.capacityList}
                                                        // type='number'
                                                        size='small'
                                                        onChange={handleChangeCapacity}
                                                        onSelectValue={(name:any,value) => {
                                                            handleChangeCapacity({target: {name: name, value: value}})
                                                        }}
                                                        error={!!errors?.capacity}
                                                        helperText={errors?.capacity?.message}
                                                        height= {32}
                                                        maxLength={6}
                                                        
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">一次側定格電流(A):</Typography>
                                        <Controller
                                            name='ratedCurrentParmary'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="100%">二次側定格電流(A):</Typography>
                                        <Controller
                                            name='ratedCurrentSecondary'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32 ,color:'grey'}}}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={6} pl={3}>
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='fault'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={checkBoxStyles}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>短絡電流を計算</Typography>}
                                    />
                                </Stack>
                                {/* Mode SP */}
                                {(userRole && !(userRole == Constant.ROLE_LP)) &&
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewFlowResult'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={checkBoxStyles}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>潮流解析結果を表示</Typography>}
                                    />
                                </Stack>
                                }
                                <Stack>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                name='viewResultText'
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        disabled={m_bModePM || modeViewOnly}
                                                        size='small'
                                                        checked={field.value}
                                                        onChange={handleChangeCheckbox}
                                                        style={checkBoxStyles}
                                                    />
                                                )}
                                            />
                                        }
                                        label={<Typography variant='body2' color={m_bModePM?'grey':'black'}>注釈を表示</Typography>}
                                    />
                                </Stack>
                                <fieldset style={{border:'1px solid #AAAA'}}>
                                    <legend ><Typography variant="body2">電圧調整タブの設定</Typography></legend>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="50%">タブ値(V):</Typography>
                                        <Controller
                                            name='voltageAdjust'
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat2}
                                                    inputProps={{maxlength:6,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                            color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </fieldset>
                                
                            </Grid>
                        </Grid>
                        <fieldset style={{border:"1px solid #AAAA",marginTop:"10px"}}>
                            <legend><Typography variant="body2">インピーダンス(機器容量ベース)</Typography></legend>
                            <Stack spacing={0.3}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="100%">X/R:</Typography>
                                            <Controller
                                                name="xr"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '  数字 >= 0 を入力してください。' ,
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        // type="number"
                                                        size="small"
                                                        onChange={(e) => {field.onChange(e);handleChangePerXRZ(e)}}
                                                        sx={{ width: 200 }}
                                                        inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.xr}
                                                        helperText={errors?.xr?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width='100%'>%インピーダンス(%):</Typography>
                                            <Controller
                                                name="percentZ"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        // type="text"
                                                        size="small"
                                                        sx={{ width: 200 }}
                                                        inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        onChange={(e) => {field.onChange(e);handleChangePerXRZ(e)}}
                                                        error={!!errors?.percentZ}
                                                        helperText={errors?.percentZ?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="percentR"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '  数字 >= 0 を入力してください。' ,
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        onChange={(e) =>{field.onChange(e);handleChangePerR(e)}}
                                                        inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.percentR}
                                                        helperText={errors?.percentR?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="percentX"
                                                control={control}
                                                rules={{
                                                    pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '  数字 >= 0 を入力してください。' ,
                                                    }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        onChange={(e) =>{field.onChange(e);handleChangePerX(e)}}
                                                        inputProps={{maxlength:5,readOnly: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}}
                                                        InputProps={{ 
                                                            sx: { 
                                                                height: 32,
                                                                background:m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'floralwhite':'white',
                                                                color: m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING?'grey':'black',
                                                            }
                                                        }}
                                                        error={!!errors?.percentX}
                                                        helperText={errors?.percentX?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width='100%'>インピーダンス(mΩ):</Typography>
                                            <Controller
                                                name="ohmZ"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        sx={{ width: 200 }}
                                                        InputProps={{sx: { height: 32,color:'grey'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>=</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="ohmR"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32 ,color:'grey'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={0.5} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'center' }}>
                                        <span>+ j</span>
                                    </Grid>
                                    <Grid item xs={2.5}>
                                        <Stack>
                                            <Controller
                                                name="ohmX"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="text"
                                                        size="small"
                                                        inputProps={{ readOnly: true }}
                                                        style={{ backgroundColor: 'floralwhite' }}
                                                        InputProps={{sx: { height: 32,color:'grey'}}}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </fieldset>

                        {/* ModePM */}
                        {[Constant.ROLE_PM, Constant.ROLE_ADMIN].includes(userRole) &&
                        <>
                            <Stack mt={1}>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            name='makeDataInput'
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    size='small'
                                                    {...field}
                                                    disabled={m_bModePM || modeViewOnly}
                                                    checked={field.value}
                                                    onChange={handleChangeMakeDataInput}
                                                    style={{padding: '4px 4px 4px 9px'}}
                                                />
                                            )}
                                        />
                                    }
                                    label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>銘板入力</Typography>}
                                />
                            </Stack>
                            <Stack>
                                <fieldset
                                    style={{ 
                                        width: '60%',
                                        color: m_bModePM || !makeDataInput ? 'grey' : 'black', 
                                        // borderColor: !makeDataInput ? 'white' : undefined 
                                        border:"1px solid #AAAA"
                                    }}
                                >
                                    <legend><Typography variant="body2"  >銘板</Typography></legend>
                                    <Stack spacing={0.3}>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >製造者:</Typography>
                                            <FormControl fullWidth size='small'>
                                                <Controller
                                                    name='makerName'
                                                    control={control}
                                                    render={({ field }: any) => (
                                                        <TextFieldOptions
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                            options={initialValue.makerNameList}
                                                            type='string'
                                                            size='small'
                                                            setValue={(name: any, value: any) => {
                                                                handleChangeFormat({target: {name: name, value: value}})
                                                            }}
                                                            height= {32}
                                                        />
                                                    )}
                                                />
                                            </FormControl>
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >形式:</Typography>
                                            <Controller
                                                name='type'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{
                                                            readOnly: m_bModePM || modeViewOnly || !makeDataInput,
                                                            style: { color: m_bModePM || modeViewOnly || !makeDataInput ? 'grey' : 'black' },
                                                        }}
                                                        style={{
                                                            backgroundColor: m_bModePM || modeViewOnly || !makeDataInput ? 'floralwhite' : 'white',
                                                        }}
                                                        InputProps={{ sx: { height: 32 } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >製造年月:</Typography>
                                            <Grid container>
                                                <Grid item xs={7}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeYear'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={m_bModePM || modeViewOnly || !makeDataInput}
                                                                        options={initialValue.makeYearList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pr={1.5} pl={0.5}>年</Typography>
                                                    </Stack>
                                                    
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                                        <FormControl fullWidth size='small'>
                                                            <Controller
                                                                name='makeMonth'
                                                                control={control}
                                                                render={({ field }: any) => (
                                                                    <TextFieldOptions
                                                                        {...field}
                                                                        disabled={(m_bModePM || modeViewOnly || !makeDataInput)}
                                                                        options={initialValue.makeMonthList}
                                                                        type='string'
                                                                        size='small'
                                                                        setValue={(name: any, value: any) => {
                                                                            handleChangeFormat({target: {name: name, value: value}})
                                                                        }}
                                                                        height= {32}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                        <Typography variant="body2" pl={0.5}>月</Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            
                                            
                                        </Stack>
                                        <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                            <Typography variant="body2" width="35%"  >製造番号:</Typography>
                                            <Controller
                                                name='makeNumber'
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type='text'
                                                        size='small'
                                                        onChange={handleChangeFormat}
                                                        inputProps={{
                                                            readOnly: m_bModePM || modeViewOnly || !makeDataInput,
                                                            style: { color: m_bModePM || modeViewOnly || !makeDataInput ? 'grey' : 'black' },
                                                        }}
                                                        style={{
                                                            backgroundColor: m_bModePM || modeViewOnly || !makeDataInput ? 'floralwhite' : 'white',
                                                        }}
                                                        InputProps={{ sx: { height: 32 } }}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                    </Stack>
                                </fieldset>
                            </Stack>
                        </>
                        }
                    </TabPanel>

                    {/* tab1 */}
                    <TabPanel value={selectTab} index={1}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend><Typography variant="body2">励磁突入電流曲線</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name={"dispInrush"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly}
                                                            size='small'
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            style={checkBoxStyles}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                        <Controller
                                            name={"inrushTimes"}
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。',
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) =>{field.onChange(e);handleChangeInrushTimes(e)}}
                                                    inputProps={{maxlength:4, readOnly: m_bModePM || modeViewOnly }}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color:m_bModePM || modeViewOnly?'grey':'black'
                                                        }
                                                    }}
                                                    error={!!errors?.inrushTimes}
                                                    helperText={errors?.inrushTimes?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                        <Controller
                                            name={"inrushValue"}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32, color:'grey' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">減衰時定数(ms):</Typography>
                                        <Controller
                                            name={"dampingTime"}
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。',
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                    inputProps={{maxlength:5, readOnly: m_bModePM || modeViewOnly }}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color:m_bModePM || modeViewOnly?'grey':'black'
                                                        }
                                                    }}
                                                    error={!!errors?.dampingTime}
                                                    helperText={errors?.dampingTime?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </fieldset>
                        </Stack>
                        <Stack mt={2} width={'75%'}>
                            <fieldset style={{border:"1px solid #AAAA"}}>
                                <legend><Typography variant="body2">熱的機械的強度ポイント</Typography></legend>
                                <Stack spacing={0.3}>
                                    <Stack>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name={"dispIntensity"}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly}
                                                            size='small'
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            style={checkBoxStyles}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>グラフに表示する</Typography>}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">定格電流の最大倍率(倍):</Typography>
                                        <Controller
                                            name={"intensityTimes"}
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。',
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) =>{field.onChange(e);handleChangeIntensityTimes(e)}}
                                                    inputProps={{maxlength:4, readOnly: m_bModePM || modeViewOnly }}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color:m_bModePM || modeViewOnly?'grey':'black'
                                                        }
                                                    }}
                                                    error={!!errors?.intensityTimes}
                                                    helperText={errors?.intensityTimes?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">最大電流(Arms):</Typography>
                                        <Controller
                                            name={"intensityValue"}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite' }}
                                                    InputProps={{ sx: { height: 32, color:'grey' } }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" }}>
                                        <Typography variant="body2" width="75%">時間(s):</Typography>
                                        <Controller
                                            name={"intensityTime"}
                                            control={control}
                                            rules={{ required: '必須項目です。入力してください。',
                                                pattern:{
                                                    value: /^[0-9]*[.]?[0-9]+$/,
                                                    message: '数字を入力してください。'
                                                },
                                                validate: value => value > 0 || '数字 > 0 を入力してください。' ,
                                            }}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) =>{field.onChange(e);handleChangeFormat(e)}}
                                                    inputProps={{maxlength:4, readOnly: m_bModePM || modeViewOnly }}
                                                    InputProps={{ 
                                                        sx: { 
                                                            height: 32,
                                                            background:m_bModePM || modeViewOnly?'floralwhite':'white',
                                                            color:m_bModePM || modeViewOnly?'grey':'black' 
                                                        }
                                                    }}
                                                    error={!!errors?.intensityTime}
                                                    helperText={errors?.intensityTime?.message}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                            </fieldset>
                        </Stack>
                    </TabPanel>

                    {/* tab2 */}
                    <TabPanel value={selectTab} index={2}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropMotor"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                checked={field.value}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>電動機始動時の電圧降下を計算</Typography>}
                                                name='dropMotor'
                                                onChange={handleChangeCheckbox}
                                                control={<Checkbox style={checkBoxStyles} />}
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid item xs={10}>
                                <Controller
                                        name="dropRegular"
                                        control={control}
                                        render={({ field }: any) => (
                                            <FormControlLabel
                                                {...field}
                                                disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                checked={field.value}
                                                label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>定常時の電圧降下を計算</Typography>}
                                                name='dropRegular'
                                                onChange={(e) =>hanDleChangedropRegular(e)} 
                                                control={<Checkbox style={checkBoxStyles} />}
                                            />
                                        )}
                                    />
                            </Grid>
                            <Grid container>
                                <Grid item xs={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷容量 (VA):</Typography>  
                                            <Controller
                                                name="calcPoint0.loadCapacity"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        type="number"
                                                        size="small"
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        onChange={(e) =>{field.onChange(e);handleChangeLoadCapacity(e)}}
                                                        inputProps={{ readOnly: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}}
                                                        style={{
                                                            backgroundColor: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING? 'floralwhite' : 'white',
                                                        }}
                                                        InputProps={{sx:{height:32,color:m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING?'grey':'black'}}}
                                                        error={!!errors?.calcPoint0?.loadCapacity}
                                                        helperText={errors?.calcPoint0?.loadCapacity?.message}
                                                    />
                                                )}
                                            />
                                        </Stack>
                                </Grid>
                                <Grid item xs ={7.5}>
                                    <Stack sx={{ flexDirection: "row", alignItems:"center" ,marginTop:"2px"}}>
                                        <Typography variant="body2" width="90%" ml={5}>負荷電流 IL(A):</Typography>  
                                            <Controller
                                                name="calcPoint0.loadCurrent"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 || '数字 >= 0 を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        // type="text"
                                                        size="small"
                                                        // label="負荷電流 IL(A)"
                                                        // onBlur={handleChangeFormat}
                                                        onFocus={e => e.target.select()}
                                                        InputLabelProps={{ style: { color: 'black' }}}
                                                        onChange={(e) =>{field.onChange(e);handleChangeLoadCurrent(e)}}
                                                        inputProps={{maxlength:7, readOnly: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}}
                                                        style={{
                                                            backgroundColor: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING? 'floralwhite' : 'white',
                                                        }}
                                                        InputProps={{ sx: { height: 32 ,color:m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING?'grey':'black'} }}
                                                        error={!!errors?.calcPoint0?.loadCurrent}
                                                        helperText={errors?.calcPoint0?.loadCurrent?.message}
                                                    />
                                                )}
                                            />
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container ml={6.5}>
                                <fieldset style={{border:"1px solid #AAAA",width:"53%"}}>
                                    <legend>{<Typography variant="body2">負荷電流方向</Typography>}</legend>
                                        <Controller
                                                name="currentTopLeft"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup {...field}>
                                                        <FormControlLabel 
                                                            value='1'
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>上→下 or 左→右</Typography>  }
                                                        />
                                                        <FormControlLabel
                                                            value='0'
                                                            disabled={m_bModePM || modeViewOnly || processMode > MS_MODE_CHECKING}
                                                            control={<Radio style={radioStyles} />}
                                                            label={<Typography variant="body2" color={m_bModePM || processMode > MS_MODE_CHECKING?'grey':'black'}>下→上 or 右→左</Typography>  }
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </fieldset>
                                        <Stack ml={1.2}>
                                            <Typography color="common.white">nothing</Typography>
                                            <Typography color="common.white">nothing</Typography>
                                            <Button variant="outlined" disabled={m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING} 
                                                                        onClick={handleResetCurrentTopLeft}
                                                            >概算値</Button>
                                        </Stack>
                            </Grid>
                            <Grid container ml={3}>
                                <fieldset style={{width:"76%",border:"1px solid #AAAA"}}>
                                    <legend>{<Typography variant="body2">回路の力率</Typography>}</legend>
                                    <Grid container>
                                        <Grid item xs={5}>
                                            <Controller
                                                name="calcPoint0.isLead"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup 
                                                        {...field} 
                                                        row
                                                        onChange={handleChangeFormat2}
                                                    >
                                                        <FormControlLabel 
                                                            value={true}
                                                            control={<Radio size='small' style={radioStyles} />}
                                                            label={<Typography style ={{color:m_bModePM || !dropRegular || processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">進み</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}
                                                        />
                                                        <FormControlLabel 
                                                            value={false}
                                                            control={<Radio size='small' style={radioStyles} />}
                                                            label={<Typography style ={{color:m_bModePM || !dropRegular || processMode > MS_MODE_CHECKING?"grey":"black"}} variant="body2">遅れ</Typography>}
                                                            disabled={m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={1}/>
                                        <Grid xs={2.75} ml={1.4}>
                                            <Controller
                                                name="calcPoint0.powerFactor"
                                                control={control}
                                                rules={{ required: '必須項目です。入力してください。',
                                                    pattern:{
                                                        value: /^[0-9]*[.]?[0-9]+$/,
                                                        message: '数字を入力してください。'
                                                    },
                                                    validate: value => value >= 0 && value <= 1 || '数字 0 ~ 1  を入力してください。' ,
                                                }}
                                                render={({ field }) => (
                                                    <TextField
                                                    {...field}
                                                    fullWidth
                                                    // type='number'
                                                    size='small'
                                                    onChange={(e) => {field.onChange(e);handleChangeFormat2(e)}}
                                                    inputProps={{maxlength:7, readOnly: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}}
                                                    style={{
                                                        backgroundColor: m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING? 'floralwhite' : 'white',
                                                    }}
                                                    InputProps={{ sx: { height: 32,color:m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING?'grey':'black' } }}
                                                    error={!!errors?.calcPoint0?.powerFactor}
                                                     helperText={errors?.calcPoint0?.powerFactor?.message}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={2.5} ml={1.64}>
                                            <Button style={{margin:"0"}} variant="outlined" onClick={handleResetLeadLag}
                                                        disabled={m_bModePM || modeViewOnly || !dropRegular || processMode > MS_MODE_CHECKING}
                                                        >概算値</Button>
                                        </Grid>
                                    </Grid>
                                </fieldset>
                            </Grid>
                            <Grid container  mt={0.25}>
                                <Grid container flexDirection={"row"} ml={6} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下(V):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop1"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    type='number'
                                                    size='small'
                                                    onChange={handleChangeFormat}
                                                    inputProps={{ readOnly: true }}
                                                    style={{ backgroundColor: 'floralwhite',width:"70%",marginLeft:'20%' }}
                                                    InputProps={{ sx: { height: 32 ,color:'grey'} }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container flexDirection={"row"} ml={6} mt={0.25} alignItems={"center"}>
                                    <Grid item xs={4} >
                                        <Typography variant="body2" width="100%" >単体での電圧降下率(%):</Typography>  
                                    </Grid>
                                    <Grid item xs={4} ml={-2.6}>
                                        <Controller
                                            name="voltageDrop"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                {...field}
                                                fullWidth
                                                type='number'
                                                size='small'
                                                onChange={handleChangeFormat}
                                                inputProps={{ readOnly: true }}
                                                style={{ backgroundColor: 'floralwhite' ,width:"70%",marginLeft:'20%'  }}
                                                InputProps={{ sx: { height: 32,color:'grey' } }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    {/* tab3 */}
                    <TabPanel value={selectTab} index={3}>
                        <Stack mt={1} width={'75%'}>
                            <fieldset style={{border:'1px solid #AAAA'}}>
                                <legend ><Typography variant="body2">単線結線図で表示するシンボルの選択</Typography></legend>
                                <Grid container>
                                    <Grid item xs={5}>
                                        <Controller
                                            name='symKind'
                                            control={control}
                                            render={({ field }) => (
                                                <RadioGroup {...field}>
                                                    <FormControlLabel 
                                                        value='0' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>無し</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='1' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／△</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='2' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>Ｙ／Ｙ</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='3' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>△／Ｙ</Typography>}
                                                    />
                                                    <FormControlLabel 
                                                        value='4' 
                                                        disabled={m_bModePM || modeViewOnly}
                                                        control={<Radio size='small'/>} 
                                                        label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>△／△</Typography>}
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <FormControlLabel
                                            control={
                                                <Controller
                                                    name='haveTab'
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            disabled={m_bModePM || modeViewOnly}
                                                            size='small'
                                                            checked={field.value}
                                                            onChange={handleChangeCheckbox}
                                                            style={checkBoxStyles}
                                                        />
                                                    )}
                                                />
                                            }
                                            label={<Typography variant="body2" color={m_bModePM?'grey':'black'}>負荷時電圧調整機能付き</Typography>}
                                        />
                                    </Grid>
                                </Grid>
                            </fieldset>
                        </Stack>
                    </TabPanel>
                </div>
                <Stack direction='row' justifyContent='flex-end' mt={2} spacing={2}>
                    <Button variant='contained' type='submit' disabled={modeViewOnly}>OK</Button>
                    <Button color='inherit' variant='contained' onClick={handleCancel}>キャンセル</Button>
                </Stack>
            </form >
        </>
    );
};

export default MsTrans3EditDialog;