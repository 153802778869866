import { Node, NodeConfig } from "konva/lib/Node";
import * as Model from "../models/Index";
import { SET_NOTIFICATION_LIST, CREATE_CHART_TAB, DELETE_GRAPH_BY_GRAPH_NO, DELETE_GRAPH_TEXT_BY_RELATED_GRAPH, DELETE_TC_GROUP, DIAGRAM_DATA_UPDATE, DISPLAY_DEVICE_NAME, IS_DOWNLOAD_DIAGRAM_PDF, IS_SHOW_MAINTENANCE_SCREEN, IS_SHOW_SCREEN, REDRAW_GRAPH_TEXT, SAVE_LIST_GRAPH, SAVE_LIST_GRAPH_NAME, SET_REPOSITION_ELEMENT, UPDATE_QUICK_PROPERTIES, IS_SHOW_MY_PROJECT } from "./AppStore";
import { ControlModel } from "../models/Index";

//#region Constants
export const SAVE_ELEMENT_TEMPLATES = "SAVE_ELEMENT_TEMPLATES";
export const SAVE_ALL_CONTROLS_TO_DIAGRAM = "SAVE_ALL_CONTROLS_TO_DIAGRAM";
export const SAVE_SHAPES = "SAVE_SHAPES";
export const SAVE_CHART_INTO_CONTROL = "SAVE_CHART_INTO_CONTROL";
export const DELETE_DIAGRAM_BY_TABID = "DELETE_DIAGRAM_BY_TABID";
export const SAVE_CURRENT_ID_DIAGRAM_TAB = "SAVE_CURRENT_ID_DIAGRAM_TAB";
export const ADD_UNDO_DATA_TO_CHART = "ADD_UNDO_DATA_TO_CHART";
export const SAVE_ALL_CONTROLS_TO_CHART_TAB = "SAVE_ALL_CONTROLS_TO_CHART_TAB";
export const UPDATE_LINE_INFO = "UPDATE_LINE_INFO";
export const DELETE_CHART_TAB_BY_TABID = "DELETE_CHART_TAB_BY_TABID";
export const DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG = "DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG";
export const SAVE_CURRENT_ID_CHART_TAB = "SAVE_CURRENT_ID_CHART_TAB";
export const SAVE_MAXID_CHART_TAB = "SAVE_MAXID_CHART_TAB";
export const PUBLISH_CHART_EVENTS = "PUBLISH_CHART_EVENTS";
export const OPEN_DIALOG_EVENTS = "OPEN_DIALOG_EVENTS";
export const SET_GRAPH_DATA = "SET_GRAPH_DATA";
export const OPEN_USER_CURVE_EVENTS = "OPEN_USER_CURVE_EVENTS";
export const OPEN_USER_CURVE_PROPERTIES_EVENTS = "OPEN_USER_CURVE_PROPERTIES_EVENTS";
export const SET_GAP_LINE = "SET_GAP_LINE";
export const SAVE_NOMINAL_VOLT_LIST = "SAVE_NOMINAL_VOLT_LIST";
export const SAVE_NEW_NOMINAL_VOLT = "SAVE_NEW_NOMINAL_VOLT";
export const TOGGLE_JP_ELECTRIC_MODE = "TOGGLE_JP_ELECTRIC_MODE";
export const SAVE_CHART_ZOOM = "SAVE_CHART_ZOOM";
export const SAVE_CHART_INTO_CHARTDATA = "SAVE_CHART_INTO_CHARTDATA";
export const ELEMENT_MAX_ID = "ELEMENT_MAX_ID";
export const TYPES_MAX_ID = "TYPES_MAX_ID";
export const SET_DATA_UNDO = "SET_DATA_UNDO";
export const OPEN_CHART = "OPEN_CHART";
export const ADD_LINE_TO_CHART = "ADD_LINE_TO_CHART";
export const IS_DRAW_POWER_ARROW = "IS_DRAW_POWER_ARROW";
export const IS_ADD_DRAW_POWER_ARROW = "IS_ADD_DRAW_POWER_ARROW";
export const DELETE_GRAPH_SCREEN = "DELETE_GRAPH_SCREEN";
export const ADD_CALC_POINT_TO_CHART = "ADD_CALC_POINT_TO_CHART";
export const ROTATE = "ROTATE";
export const UPDATE_SELECT_CONTROL = "UPDATE_SELECT_CONTROL";
export const IS_SELECT_TOUCH_GROUP = "IS_SELECT_TOUCH_GROUP";
export const CLEAR_OPTION_MENU = "CLEAR_OPTION_MENU";
export const UNSELECT_ALL_CONTROLS = "UNSELECT_ALL_CONTROLS";
export const SET_COPY_DATA = "SET_COPY_DATA";
export const COPY_SHAPES = "COPY_SHAPES";
export const CUT_SHAPES = "CUT_SHAPES";
export const PASTE_SHAPES = "PASTE_SHAPES"; 
export const REMOVE_SHAPES = "REMOVE_SHAPES";
export const GROUP_SHAPES = "GROUP_SHAPES";
export const TC_GROUP_SHAPES = "TC_GROUP_SHAPES";
export const ADD_NEW_TC_GROUP = "ADD_NEW_TC_GROUP";
export const UN_GROUP_SHAPES = "UN_GROUP_SHAPES";
export const TC_UN_GROUP_SHAPES = "TC_UN_GROUP_SHAPES";
export const SET_POINTER_POSITION = "SET_POINTER_POSITION";
export const UPDATE_PROPERTIES_OF_CONTROL = "UPDATE_PROPERTIES_OF_CONTROL";
export const UPDATE_PROPERTIES_OF_TRANS_CENTER = "UPDATE_PROPERTIES_OF_TRANS_CENTER";
export const UPDATE_CONTROL_HEIGHT = "UPDATE_CONTROL_HEIGHT";
export const UPDATE_ELEMENT_POSITION = "UPDATE_ELEMENT_POSITION";
export const UPDATE_CONTROL_WIDTH = "UPDATE_CONTROL_WIDTH";
export const SET_TRANSFORM_DATA = "SET_TRANSFORM_DATA";
export const CHANGE_PROCESS_MODE = "CHANGE_PROCESS_MODE";
export const SET_TRANSFORM_FLAG = "SET_TRANSFORM_FLAG";
export const ADD_USER_CURVE = "ADD_USER_CURVE"
export const UPDATE_USER_CURVE = "UPDATE_USER_CURVE"
export const DELETE_USER_CURVE = "DELETE_USER_CURVE"
export const SAVE_GROUP_LIST = "SAVE_GROUP_LIST";
export const SAVE_GRAPH_DATA = "SAVE_GRAPH_DATA";
export const SET_CHART_ID = "SET_CHART_ID";
export const DELETE_RELATED_GRAPH_BY_ID = "DELETE_RELATED_GRAPH_BY_ID";
export const CHANGE_MODE_PM = "CHANGE_MODE_PM";
export const UPDATE_USER_INFO = "UPDATE_USER_INFO";
export const CHANGE_MODE_VIEW_ONLY = "CHANGE_MODE_VIEW_ONLY";
export const PRESS_CONTEXT_MENU_FUNCTION = "PRESS_CONTEXT_MENU_FUNCTION"
//#endregion

//#region Actions
export const updateUserInfo = (data: any) => {
    return {
        type: UPDATE_USER_INFO,
        payload: data,
    };
};

export const changeModePM = (data: Boolean) => {
    return {
        type: CHANGE_MODE_PM,
        payload: data,
    };
};

export const changeModeViewOnly = (data: Boolean) => {
    return {
        type: CHANGE_MODE_VIEW_ONLY,
        payload: data,
    };
};

export const saveElementTemplates = (templates: object) => {
    return {
        type: SAVE_ELEMENT_TEMPLATES,
        payload: templates,
    };
};

export const setDiagramDataUpdate = () => {
    return {
        type: DIAGRAM_DATA_UPDATE
    };
};

export const saveAllControlsToDiagram = (data: Model.DiagramModel, flagSavePreStateDiagram: boolean, bDiagramUpdate?:boolean) => {
    return {
        type: SAVE_ALL_CONTROLS_TO_DIAGRAM,
        payload: data,
        flagSavePreStateDiagram: flagSavePreStateDiagram,
        bDiagramUpdate: bDiagramUpdate
    };
};

export const setShapes = (data: Model.DiagramModel,controlChange:Model.ControlModel[], flagSavePreStateDiagram: boolean) => {
    return {
        type: SAVE_SHAPES,
        payload: data,
        controlChange:controlChange,
        flagSavePreStateDiagram: flagSavePreStateDiagram
    };
};

export const saveChartIntoControl = (data: Model.ControlModel) => {
    return {
        type: SAVE_CHART_INTO_CONTROL,
        payload: data,
    };
};

export const deleteDiagramByTabId = (data: number) => {
    return {
        type: DELETE_DIAGRAM_BY_TABID,
        payload: data,
    };
};

export const deleteRelatedGraphById = (id: string) => {
    return {
        type: DELETE_RELATED_GRAPH_BY_ID,
        payload: id,
    };
};

export const deleteGraphByGraphNo = (graphNo: string) => {
    return {
        type: DELETE_GRAPH_BY_GRAPH_NO,
        payload: graphNo,
    };
};

export const isShowScreen = (screen: string) => {
    return {
        type: IS_SHOW_SCREEN,
        payload: screen
    };
};

export const setRepositionElement = (id: any, state: boolean) => {
    return {
        type: SET_REPOSITION_ELEMENT,
        payload: {
            id,
            state
        }
    };
};

export const isDownloadDiagramPDF = (state: boolean, type: number, image: any) => {
    return {
        type: IS_DOWNLOAD_DIAGRAM_PDF,
        payload: {
            state,
            type,
            image
        }
    };
};

export const saveCurrentIdDiagramTab = (data: Number) => {
    return {
        type: SAVE_CURRENT_ID_DIAGRAM_TAB,
        payload: data,
    };
};

export const addUndoDataToChart = (data: any) => {
    return {
        type: ADD_UNDO_DATA_TO_CHART,
        payload: data,
    };
};

export const saveGroupList = (data: Model.GroupListModel[]) => {
    return {
        type: SAVE_GROUP_LIST,
        payload: data,
    };
};

export const saveAllControlsToChartTab = (data: Model.ControlModel[], flagEdit: boolean) => {
    return {
        type: SAVE_ALL_CONTROLS_TO_CHART_TAB,
        payload: data,
        flagEdit: flagEdit
    };
};

export const updateLineInfo = (data : Model.LineStyleModel, lineId : string | number, controlId : string) => {
    return {
        type: UPDATE_LINE_INFO,
        payload: {
            data,
            lineId,
            controlId
        },
    };
};

export const deleteChartTabByTabId = (data: number) => {
    return {
        type: DELETE_CHART_TAB_BY_TABID,
        payload: data,
    };
};

export const deleteRelatedGraphByUserCurveDialog = (data: number) => {
    return {
        type: DELETE_RELATED_GRAPH_BY_USER_CURVE_DIALOG,
        payload: data,
    };
};

export const deleteGraphTextByRelatedGraph = (data: any) => {
    return {
        type: DELETE_GRAPH_TEXT_BY_RELATED_GRAPH,
        payload: data,
    };
};

export const reDrawGraphText = (data: any) => {
    return {
        type: REDRAW_GRAPH_TEXT,
        payload: data,
    };
};

export const saveCurrentIdChartTab = (data: Number) => {
    return {
        type: SAVE_CURRENT_ID_CHART_TAB,
        payload: data,
    };
};

export const saveMaxIdChartTab = (maxId: number, flag: boolean) => {
    return {
        type: SAVE_MAXID_CHART_TAB,
        payload: {
            maxId: maxId,
            flag: flag
        },

    };
};

export const createChartTab = (id: number) => {
    return {
        type: CREATE_CHART_TAB,
        payload: id,
    };
};

export const publishChartEvents = (payload : any) => {
    return {
        type: PUBLISH_CHART_EVENTS,
        payload,
    };
};

export const openDialogEvents = (payload : any) => {
    return {
        type: OPEN_DIALOG_EVENTS,
        payload,
    };
};

export const setGraphData = (payload : any) => {
    return {
        type: SET_GRAPH_DATA,
        payload,
    };
};

export const openUserCurveEvents = (payload : any) => {
    return {
        type: OPEN_USER_CURVE_EVENTS,
        payload,
    };
};

export const openUserCurvePropertiesEvents = (payload : any) => {
    return {
        type: OPEN_USER_CURVE_PROPERTIES_EVENTS,
        payload,
    };
};

export const setGapLine = (payload : any) => {
    return {
        type: SET_GAP_LINE,
        payload,
    };
};

export const saveNominalVoltList = (payload : number[]) => {
    return {
        type: SAVE_NOMINAL_VOLT_LIST,
        payload,
    };
};

export const saveNewNominalVolt = (nominalVolt : number, currentIDChartTab : number) => {
    return {
        type: SAVE_NEW_NOMINAL_VOLT,
        payload: {
            nominalVolt, 
            currentIDChartTab
        }
    };
};

export const toggleJapanElectricMode = (currentIDChartTab : number) => {
    return {
        type: TOGGLE_JP_ELECTRIC_MODE,
        payload: {
            currentIDChartTab
        }
    };
};

export const saveChartZoom = (zoom : number, currentIDChartTab : number) => {
    return {
        type: SAVE_CHART_ZOOM,
        payload: {
            zoom, 
            currentIDChartTab
        }
    };
};

export const saveChartIntoChartData = (data:any, tabId: number, controlId:any) => {
    return {
        type: SAVE_CHART_INTO_CHARTDATA,
        payload: {
            data, 
            tabId,
            controlId
        }
    };
};

export const saveElementMaxId = (maxId: number) => {
    return {
        type: ELEMENT_MAX_ID,
        payload: maxId,
    };
};

export const setChartId = (tempId: string, id: string, textId: string, tabId: number) => {
    return {
        type: SET_CHART_ID,
        payload: {
            tempId,
            id,
            textId,
            tabId,
        }
    };
};

export const saveTypesMaxId = (typeMaxId : number, elementNoKey : string) => {
    return {
        type: TYPES_MAX_ID,
        payload: {
            typeMaxId,
            elementNoKey,
        }
    };
};

export const undo = (data: Model.UndoModel) => {
    return {
        type: SET_DATA_UNDO,
        payload: data,
    };
};

export const openChart = (isOpenChart: boolean, lineType : string = Model.LineType.CONTROL_CURVE, lineId : number = 0) => {
    return {
        type: OPEN_CHART,
        payload: {
            mode: isOpenChart,
            lineType,
            lineId
        },
    };
};

export const addLineToChart = (isAddToChart: boolean, controlId: string, isUndo: boolean = false) => {
    return {
        type: ADD_LINE_TO_CHART,
        payload: {
            mode: isAddToChart,
            controlId,
            isUndo
        },
    };
};

export const isDrawPowerArrow = (data: any) => {
    return {
        type: IS_DRAW_POWER_ARROW,
        payload: data
    };
};

export const isAddDrawPowerArrow = (data: any) => {
    return {
        type: IS_ADD_DRAW_POWER_ARROW,
        payload: data
    };
};

export const deleteGraphScreen = (isOpenGraphScreen: boolean, isDelete:boolean) => {
    return {
        type: DELETE_GRAPH_SCREEN,
        payload: {
            isOpenGraphScreen,
            isDelete,
        },
    };
};

export const addCalcPointToChart = (isAddCalcPointToChart: boolean, controlId: string) => {
    return {
        type: ADD_CALC_POINT_TO_CHART,
        payload: {
            mode: isAddCalcPointToChart,
            controlId
        },
    };
};

export const rotate = (tabId: number) => {
    return {
        type: ROTATE,
        payload: tabId,
    };
};

export const updateSelectControl = (controlId: string, isMultiple : boolean = false) => {
    return {
        type: UPDATE_SELECT_CONTROL,
        payload: {
            controlId, 
            isMultiple
        },
    };
};

export const isSelectTouchGroup = (state : boolean) => {
    return {
        type: IS_SELECT_TOUCH_GROUP,
        payload: state
    };
};

export const clearOptionMenu = (state : boolean) => {
    return {
        type: CLEAR_OPTION_MENU,
        payload: state,
    };
};

export const unSelectAllControls = () => {
    return {
        type: UNSELECT_ALL_CONTROLS,
    };
};

export const setCopyData = (data: any) => {
    return {
        type: SET_COPY_DATA,
        payload: data,
    };
};

export const copyAction = (tabId: number) => {
    return {
        type: COPY_SHAPES,
        payload: tabId,
    };
};

export const cutAction = (tabId: number) => {
    return {
        type: CUT_SHAPES,
        payload: tabId,
    };
};

export const pasteAction = (data: { tabId: number, pointerPosition: Model.Coordinates }) => {
    return {
        type: PASTE_SHAPES,
        payload: data,
    };
};

export const removeAction = (tabId: number) => {
    return {
        type: REMOVE_SHAPES,
        payload: tabId,
    };
};

export const updateQuickProperties = (field:string,value:boolean) => {
    return {
        type: UPDATE_QUICK_PROPERTIES,
        payload: {field:field,value:value}
    }
}
export const isShowMaintenanceScreen = (show:boolean,controlId?:string ) =>{
    return{
        type: IS_SHOW_MAINTENANCE_SCREEN,
        payload: {show:show,controlId:controlId}
    }
}
export const isShowMyProject = (show:boolean) =>{
    return{
        type: IS_SHOW_MY_PROJECT,
        payload: show
    }
}
export const setNotificationList = (notificationList: Model.NotificationModel[]) =>{
    return{
        type: SET_NOTIFICATION_LIST,
        payload: notificationList
    }
}
export const groupAction = (data: any, newGroupEleIds: string[]) => {
    return {
        type: GROUP_SHAPES,
        payload: {
            data,
            newGroupEleIds
        },
    };
};

export const tcGroupAction = (data: any, newGroupEleIds: string[], tcId : string) => {
    return {
        type: TC_GROUP_SHAPES,
        payload: {
            data,
            newGroupEleIds,
            tcId
        },
    };
};

export const addNewTCGroups = (tcId : string) => {
    return {
        type: ADD_NEW_TC_GROUP,
        payload: tcId
    };
};

export const unGroupAction = (peekGroupIds : number[]) => {
    return {
        type: UN_GROUP_SHAPES,
        payload: peekGroupIds
    };
};

export const tcUnGroupAction = (peekTCGroupIds : number[], tcId : string) => {
    return {
        type: TC_UN_GROUP_SHAPES,
        payload: {peekTCGroupIds, tcId} 
    };
};

export const setPointerPositionAction = (pointerPosition: Model.Coordinates) => {
    return {
        type: SET_POINTER_POSITION,
        payload: pointerPosition,
    };
};

export const updatePropertiesOfControlAction = (id: string, data: any) => {
    data.isReadLists = true
    return {
        type: UPDATE_PROPERTIES_OF_CONTROL,
        payload: data,
        id: id
    };
};

export const updatePropertiesOfTransCenter = (id: string, data: any) => {
    data.isReadLists = true
    return {
        type: UPDATE_PROPERTIES_OF_TRANS_CENTER,
        payload: data,
        id: id
    };
};

export const updateControlHeight = (id: string, heigth: number, x: number, y: number, offsetY: number) => {
    return {
        type: UPDATE_CONTROL_HEIGHT,
        payload: heigth,
        x: x,
        y: y,
        offsetY: offsetY,
        id: id
    };
};

export const updateElementPosition = (id: string, x: number, y: number) => {
    return {
        type: UPDATE_ELEMENT_POSITION,
        payload: 
            {id: id,
            x: x,
            y: y}
    };
};

export const updateControlWidth = (id: string, width: number) => {
    return {
        type: UPDATE_CONTROL_WIDTH,
        payload: {id, width}
    };
};

export const setTransformData = (shapes: Node<NodeConfig>[] | undefined) => {
    return {
        type: SET_TRANSFORM_DATA,
        payload: shapes
    }
}

export const changeProcessMode = (mode : Model.ProcessMode) => {
    return {
        type: CHANGE_PROCESS_MODE,
        payload: mode
    }
}

export const setTransformFlag = (visible: boolean) => {
    return {
        type: SET_TRANSFORM_FLAG,
        payload: visible
    }
}

export const addUserCurve = (controlId : string, data : any) => {
    return {
        type: ADD_USER_CURVE,
        payload: {
            controlId,
            data
        }
    }
}

export const updateUserCurve = (controlId : string, data : any) => {
    return {
        type: UPDATE_USER_CURVE,
        payload: {
            controlId,
            data
        }
    }
}

export const deleteUserCurve = (controlId : string, data : any) => {
    return {
        type: DELETE_USER_CURVE,
        payload: {
            controlId,
            data
        }
    }
}

export const saveListGraphName = (projectId:any,data:any) => {
    return {
        type: SAVE_LIST_GRAPH_NAME,
        payload:{
            projectId,
            data
        }
    }
}

export const pressContextMenuFunction = (data: number) => {
    return {
        type: PRESS_CONTEXT_MENU_FUNCTION,
        payload: {
            data
        }
    }
}

export const deleteTcGroup = (tc_id:any) => {
    return{
        type: DELETE_TC_GROUP,
        payload:{
            tc_id
        }
    }
}

export const saveListGraph = (data:any) => {
    return{
        type: SAVE_LIST_GRAPH,
        payload:{
            projectList:data
        }
    }
}

//#endregion