import ListAltSharpIcon from '@mui/icons-material/ListAltSharp';
import { LoadingButton } from "@mui/lab";
import { Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControlLabel, Grid, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import MainHeader from "../../layouts/MainHeader";
import { ProjectModel, ShareProjectModel } from "../../models/Index";
import { Path as RoutePath } from "../../path";
import { ApplicationState, actionCreators } from "../../store";
import FetchHelper from "../FetchHelper";
import AlertDialog from "../common/AlertDialog";
import ShareProjectDialog, { ShareProjetForm } from "../dialogs/ShareProjectDialog";
import ShareProjectGrid from "./ShareProjectGrid";
import { Add } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import dayjs from 'dayjs';
import HistoryProjectGrid from './HistoryProjectGrid';
import HistoryProjectAddDialog, { HistoryProjectAddForm } from '../dialogs/HistoryProjectAddDialog';

// -------------
// FETCH ID
const UPDATE_PROJECT = "UPDATE_PROJECT";
const ADD_SHARED_USER = "ADD_SHARED_USER"
const DELETE_SHARED_USER = "DELETE_SHARED_USER"
const EDIT_SHARED_USER ="EDIT_SHARED_USER"
const ADD_EDIT_HISTORY = "ADD_EDIT_HISTORY"
const UPDATE_EDIT_HISTORY = "UPDATE_EDIT_HISTORY"
const DELETE_EDIT_HISTORY = "DELETE_EDIT_HISTORY"
const GET_SHARE_PROJECT_LIST = "GET_SHARE_PROJECT_LIST"

//Tabs------------------
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


// -------------
// Props
export type ProjectSettingProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


export const ProjectSetting = (props: ProjectSettingProps) => {
    //#region fields & hooks
    const {
        user,
        onGetShareProjectList,
        notAllowEditLockedProject,
        onGetProject,
        onUpdateProject,
        onUpdateProjectError,
        onUpdateProjectSuccess,
        onAddSharedUser,
        onEditSharedUser,
        onDeleteSharedUser,
        onAddShareProjectSuccess,
        onAddShareProjectError,
        onEditShareProjectSuccess,
        onEditShareProjectError,
        onDeleteShareProjectSuccess,
        onDeleteShareProjectError,
        onAddEditHistoryProject,
        onUpdateEditHistoryProject,
        onDeleteEditHistoryProject,
        onAddEditHistoryProjectSuccess,
        onAddEditHistoryProjectError,
        onUpdateEditHistoryProjectSuccess,
        onUpdateEditHistoryProjectError,
        onDeleteEditHistoryProjectSuccess,
        onDeleteEditHistoryProjectError
    } = props;

    const location = useLocation();
    const projectData = location.state && location.state.projectData;
    const modeShareProject = location.state && location.state.modeShareProject;
    const limitPage = location.state && location.state.limitPage;
    
    const navigate = useNavigate();

    // 初期値設定
    let initialValue: ProjectModel = {
        ...projectData
    }

    const { control, handleSubmit,setValue ,getValues} = useForm<ProjectModel>({
        criteriaMode: "all",
        defaultValues: initialValue,
    });

    const [selectTab, setSelectTab] = useState(0);
    const [loadingFlag, setLoadingFlag] = useState(false);
    const gridRefShareProject = useRef() as any;
    const gridRef = useRef() as any;

    const [colState, setColState] = useState<any>();

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    //#region HISTORY PROJECT
    const [dataHisotyProjectList, setDataHisotyProjectList] = useState<any>()
    const [idEditHistory, setIdEditHistory] = useState<any>()

    //#endregion HISTORY PROJECT

    //#region SHARE PROJECT
    const [openDeleteShareProject, setOpenDeleteShareProject] = useState<boolean>(false);
    const [openShareProject, setOpenShareProject] = useState<boolean>(false);
    const [openShareProjectDialog, setOpenShareProjectDialog] = useState<{isOpen: boolean, mode: "ADD" | "UPDATE"}>({isOpen:false,mode:"ADD"});
    const [dataOpenShareProject, setDataOpenShareProject] = useState<ShareProjetForm>({email:'',username:'',permission:'',sub:'',sharedUserId:undefined})
    const [openHistoryProjectAddDialog,setOpenHistoryProjectAddDialog] = useState<{isOpen: boolean, mode: "ADD" | "UPDATE"}>({isOpen:false,mode:"ADD"});
    const [dataHistoryProjectAdd,setDataHistoryProjectAdd] = useState<HistoryProjectAddForm>({editHistoryId:undefined,subject:'',content:'',editDatetime:'',editor:''})
    const [isDisableEditShareProject, setDisableEditShareProject] = useState(true)
    const [isDisableDeleteShareProject, setDisableDeleteShareProject] = useState(true)
    const [listUser, setListUser] = useState<any>()
    const [dataSelected, setDataSelected] = useState<any>()
    const [dataShareProjectList, setDataShareProjectList] = useState<any>()
    const [enableProject, setEnableProject] = useState<any>(initialValue.isShared)

    //This project is shared with me with editing permissions. Disable 編集共有設定 .自分以外 [権限 = 参照, 編集]
    const [disablesharedPermissionEditAndSee, setDisablesharedPermissionEditAndSee] = useState<any>(projectData.ownerId !==user?.userId)
    // view Only shared with me with see permission  see 自分以外 [権限 = 参照] view history
    const [viewOnlySharedProjects, setViewOnlySharedProjects] = useState<any>(false)
    //this project is shared with me with editing or see permissions, project name cannot be changed
    const [viewOnlyTitleProjects, setViewOnlyTitleProjects] = useState<any>(false)

    //編集ロック中: Not allowed while editing is locked
    const [notAllowEdit,setNotAllowEdit] = useState(false)
    const [ownerNameProject,setOwnerNameProject] = useState()
    const [shareProject,setShareProject] = useState(false)
    const [backgroundcolorHistoryBt,setBackgroundcolorHistoryBt] = useState(false)




    useEffect(()=>{
        if(!modeShareProject){
            onGetProject({
                requestPrms: {
                    userId: user?.userId,
                    limit: limitPage.limit,
                    page: limitPage.page,
                    projectId:projectData.projectId
                }
            })
        }
        else{
            onGetShareProjectList({
                requestPrms: {
                    userId: user?.userId,
                    limit: limitPage.limit,
                    page: limitPage.page
                }
            })
        }
            setLoadingFlag(true)
    },[])

    const handleGetProjectSuccess =(success:any,data:any,error:any) =>{
            let listUser = handleGetListUser(data.listUser.data)
            data.data.forEach((item:any)=>{
                if(item.projectId == projectData.projectId){
                    setValue("projectName",item.projectName)
                    setEnableProject(item.isShared)
                    if(item.lockedUserId !== null){
                        setNotAllowEdit(true)
                    }
                    const owner = listUser.filter((user:any) => user.sub == item.sub)
                    setOwnerNameProject(owner[0].family_name + owner[0].given_name)
                    setShareProject(item.isShared)
                }
            })
            
            // HISTORY PROJECT
            let dataHistoryByProjectIdAfter:any = []
            data.editHistory.forEach((e:any) => {
                if (e.projectId == projectData.projectId){
                    dataHistoryByProjectIdAfter.push(e)
                }
                
            })
            let dataHistoryList:any = []
            dataHistoryByProjectIdAfter.forEach((item:any) =>{
                const datetime = dayjs(item.editDatetime).format("YYYY/MM/DD")
                dataHistoryList.push({editHistoryId:item.editHistoryId,subject:item.subject,content:item.content,editDatetime:datetime,editor:item.editor})
            })
            gridRef.current.setRowData(dataHistoryList)
            setDataHisotyProjectList(dataHistoryList)

            
            //SHARE PROJECT
            let dataShareProjectAfter:any = []
                data.shareProject.forEach((e:any) => {
                    if (e.projectId == projectData.projectId){
                        dataShareProjectAfter.push(e)
                    }
                    
                })            
            dataShareProjectAfter.forEach((item:any) =>{
                if(item.ownerId !== user?.userId && item.permission =="view" && item.sharedUserId == user?.userId){
                        setViewOnlySharedProjects(true)
                }
                if(item.ownerId !== user?.userId){
                    setViewOnlyTitleProjects(true)
                }
            })
            if (!disablesharedPermissionEditAndSee){
                let listShareProject:any = []

                if (dataShareProjectAfter.length > 0) {
                    dataShareProjectAfter.forEach((data:any) =>{
                        listUser.forEach((user:any) =>{
                            if(user.sub == data.sub){
                                // const sharedDate = data.sharedDate != null? dayjs(data.sharedDate.toLocaleString()).format("YYYY/MM/DD"):data.sharedDate                        
                                const res = {ownerId:data.ownerId,projectId:data.projectId,sharedUserId:data.sharedUserId,username:user.family_name + user.given_name,permission:data.permission,sharedDate:data.sharedDate,sub:user.sub,email:user.email}
                                listShareProject.push(res)
                            }
                        })
                    })
                }
                setDataShareProjectList(listShareProject)
            }
            setListUser(listUser)
            setLoadingFlag(false)
    }
    const handleGetProjectError = (success:any,data:any,error:any) =>{
        setLoadingFlag(false)
    }

    const handleGetListUser = (data: any) => {
        const result = [] as any[];
        data.user_records?.forEach((element: any) => {
            let userItem = {} as any
            userItem.username = element.Username;
            userItem.custom_management_no = "";
            userItem.custom_company_name = "";
            userItem.custom_zip_code = "";
            userItem.address = "";
            userItem.family_name = "";
            userItem.given_name = "";
            userItem.custom_role = "";
            userItem.email = "";
            userItem.phone_number = "";
            userItem.custom_expiration_date = "";
            userItem.custom_memo = "";
            userItem.custom_mfa = "";
            userItem.custom_mfa_system = "";
            userItem.custom_plan = "";
            userItem.custom_serial_no = "";
            userItem.isSelect = false;
            userItem.sub = "";
            userItem.account_status = element.Enabled;
            
            element.Attributes.forEach((item: any) => {
                if (item.Name === "sub") {
                    userItem.sub = item.Value;
                }
                if (item.Name === "custom:management_no") {
                    userItem.custom_management_no = item.Value;
                }
                if (item.Name === "custom:company_name") {
                    userItem.custom_company_name = item.Value;
                }
                if (item.Name === "custom:zip_code") {
                    userItem.custom_zip_code = item.Value;
                }
                if (item.Name === "address") {
                    userItem.address = item.Value;
                }
                if (item.Name === "family_name") {
                    userItem.family_name = item.Value;
                }
                if (item.Name === "given_name") {
                    userItem.given_name = item.Value;
                }
                if (item.Name === "custom:role") {
                    userItem.custom_role = item.Value;
                }
                if (item.Name === "email") {
                    userItem.email = item.Value;
                }
                if (item.Name === "phone_number") {
                    userItem.phone_number = item.Value;
                }
                if (item.Name === "custom:expiration_date") {
                    userItem.custom_expiration_date = item.Value;
                }
                if (item.Name === "custom:memo") {
                    userItem.custom_memo = item.Value;
                }
                if (item.Name === "custom:mfa") {
                    if(item.Value.toLowerCase() == "true"){
                        userItem.custom_mfa = "true";
                    }else{
                        userItem.custom_mfa = "false";
                    }
                }
                if (item.Name === "custom:mfa_system") {
                    userItem.custom_mfa_system = item.Value;
                }
                if (item.Name === "custom:plan") {
                    userItem.custom_plan = item.Value;
                }
                if (item.Name === "custom:serial_no") {
                    userItem.custom_serial_no = item.Value;
                }
            })
            result.push(userItem);
        });
        return result;
    }

    //open dialog delete [共有先]
    const handleDeleteShareProject = (data:any) =>{
        let userName:any = []
        dataSelected.forEach((e:any) =>{
            userName.push(e.username)
        })
        const msg = "ユーザー名: " + userName + " を削除します。"
        setTitle("情報削除"); 
        setMessage(msg);
        setOpenDeleteShareProject(true);
    }

    //when pressing delete button in delete dialog [共有先]
    const handleDeleteShareProjectOK = () => {
        // setLoadingFlag(true);
        let params ={
            "requestPrms":{
                "dataSelected": dataSelected,
                "ownerNameProject":ownerNameProject,
                "projectName": getValues("projectName"),
                "isShared": shareProject
            }
        }
        onDeleteSharedUser(params)
        setLoadingFlag(true)
    }
    //when pressing cancel button in delete dialog [共有先]
    const handleDeleteShareProjectCancel =() =>{
        setOpenDeleteShareProject(false)
    }
    // open 共有先
    const handleOpenShareProject =(openShareProject:boolean) =>{
        setOpenShareProject(openShareProject)
        setBackgroundcolorHistoryBt(openShareProject)
    }
    //open dialog edit [共有先]
    const handleOpenShareProjectEdit =(e:any)=>{
        const permission = dataSelected[0].permission == "view"?'1':'2'
        setDataOpenShareProject({email:dataSelected[0].email,username:dataSelected[0].username,permission:permission,sub:dataSelected[0].sub,sharedUserId:dataSelected[0].sharedUserId})
        setOpenShareProjectDialog({isOpen:true,mode:"UPDATE"})
    }
    //open dialog add [共有先]
    const handleOpenShareProjectAdd =(e:any) =>{
        setDataOpenShareProject({email:'',username:'',permission:'1',sub:'',sharedUserId:undefined})
        setOpenShareProjectDialog({isOpen:true,mode:"ADD"})
    }

    const handleSelectShareProject =(data:any) =>{
        if(data.length > 0){
            setDataSelected(data)
            setDataOpenShareProject({email:'',username:data[0].username,permission:data[0].permisstion,sub:data[0].sub,sharedUserId:data[0].sharedUserId})
        }
        data.length == 1?setDisableEditShareProject(false):setDisableEditShareProject(true)
        data.length >= 1? setDisableDeleteShareProject(false): setDisableDeleteShareProject(true)
    }

     //when pressing OK button in [共有先の追加]　and [共有先の編集]  dialog 
    const handleOkShareProject =  (data:any) =>{     
        if(openShareProjectDialog.mode == "ADD"){
            const resquest = {
                "requestPrms":{
                    "ownerId": user?.userId,
                    "projectId": projectData.projectId,
                    "projectName": getValues("projectName"),
                    "ownerNameProject": ownerNameProject,
                    "permission": data.permission,
                    "sub": data.sub,
                    "isShared":shareProject
                }
            }
            onAddSharedUser(resquest)
        }
        else{
            const resquest = {
                "requestPrms":{
                    "ownerId": user?.userId,
                    "projectId": projectData.projectId,
                    "projectName": getValues("projectName"),
                    "ownerNameProject": ownerNameProject,
                    "sharedUserId": dataSelected[0].sharedUserId,
                    "permission": data.permission,
                    "sub": dataSelected[0].sub,
                    "isShared":shareProject
                }
            }
            onEditSharedUser(resquest)
        }
        setLoadingFlag(true)
    }
     //when add [共有先の追加] success
    const handleAddSharedUserSuccess =(success:any, data:any, error:any) =>{
        const dataAdd = data.data
        let res
        listUser.forEach((user:any) =>{
            if(user.sub == dataAdd.sub){
                // const sharedDate = dataAdd.sharedDate != null? dayjs(dataAdd.sharedDate.toLocaleString()).format("YYYY/MM/DD"):dataAdd.sharedDate                        
                res = {ownerId:dataAdd.ownerId,projectId:dataAdd.projectId,sharedUserId:dataAdd.sharedUserId,username:user.family_name + user.given_name,permission:dataAdd.permission,sharedDate:dataAdd.sharedDate,sub:user.sub,email:user.email}
            }
        })
        gridRefShareProject.current.addData(res);
        setDataShareProjectList([res,...dataShareProjectList])
        setOpenShareProjectDialog({isOpen: false, mode: "ADD"})
        setLoadingFlag(false)
        onAddShareProjectSuccess()

    }
     //when add [共有先の追加] error
    const handleAddSharedUserError =(success:any, data:any, error:any) =>{
        onAddShareProjectError()
        setOpenShareProjectDialog({isOpen: false, mode: "ADD"})
        setLoadingFlag(false)

    }
     //when edit [共有先の編集] success
    const handleEditSharedUserSuccess =(success:any, data:any, error:any) =>{
        if (data.data !== null){
            dataShareProjectList.forEach((e:any)=>{
                if(e.sharedUserId == data.data.sharedUserId){
                    e.permission = data.data.permission
                }
                e.isSelect = false
            })
            gridRefShareProject.current.setRowData(dataShareProjectList);
            setDataShareProjectList(dataShareProjectList)
            onEditShareProjectSuccess()
        }
        else{
            onEditShareProjectError()
        }
        handleSelectShareProject([])
        setOpenShareProjectDialog({isOpen: false, mode: "UPDATE"})
        setLoadingFlag(false)

    }
     //when edit [共有先の編集] error
    const handleEditSharedUserError =(success:any, data:any, error:any) =>{
        onEditShareProjectError()
        setOpenShareProjectDialog({isOpen: false, mode: "UPDATE"})
        setLoadingFlag(false)

    }
     //when delete [共有先の編集] success
    const handleDeleteSharedUserSuccess =(success:any, data:any, error:any) =>{
        const subDelete = data.data.map((e:any) => e.sub)
        let dataList = dataShareProjectList.filter((item:any) => !subDelete.includes(item.sub))
        gridRefShareProject.current.setRowData(dataList);
        setDataShareProjectList(dataList)
        handleSelectShareProject([])
        setOpenDeleteShareProject(false);
        setLoadingFlag(false)
        onDeleteShareProjectSuccess()

    }
     //when delete [共有先の編集] error
    const handleDeleteSharedUserError =(success:any, data:any, error:any) =>{
        setOpenDeleteShareProject(false);
        setLoadingFlag(false)
        onDeleteShareProjectError()

    }
    //#endregion SHARE PROJECT

    //#region HISTORY PROJECT
    const handleOpenHistoryProjectAddDialog =(e:any) =>{
        const datetime = dayjs(new Date().toLocaleString()).format("YYYY/MM/DD HH:mm")
        setOpenHistoryProjectAddDialog({isOpen:true,mode:'ADD'})
        const nameEditor = listUser.filter((item:any) =>item.sub == user?.userSub)[0]
        setDataHistoryProjectAdd({editHistoryId:undefined,subject:'',content:'',editDatetime:datetime,editor:nameEditor.family_name + nameEditor.given_name})
    }
    //click ok button HistoryProjectAddDialog dialog
    const handleHistoryProjectAddDialog = (data:any) =>{
        if(openHistoryProjectAddDialog.mode == 'ADD'){
            const resquest = {
                "requestPrms":{
                    "userId": user?.userId,
                    "projectId": projectData.projectId,
                    "subject": data.subject,
                    "content": data.content,
                    "editDatetime": data.editDatetime,
                    "editor": data.editor,
                    "isShared":shareProject,
                    "projectName": getValues("projectName"),
                    "ownerNameProject": ownerNameProject
                }
            }
            onAddEditHistoryProject(resquest)
        }
        else{
            const resquest = {
                "requestPrms":{
                    "userId": user?.userId,
                    "projectId": projectData.projectId,
                    "subject": data.subject,
                    "content": data.content,
                    "editDatetime": data.editDatetime,
                    "editor": data.editor,
                    "editHistoryId":idEditHistory
                }
            }
            onUpdateEditHistoryProject(resquest)
        }
        setLoadingFlag(true)
    }
    const handleAddEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        data.data.editDatetime = data.data.editDatetime
        gridRef.current.addRowData(data.data);
        setDataHisotyProjectList([data.data,...dataHisotyProjectList])
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'ADD'})
        setLoadingFlag(false)
        onAddEditHistoryProjectSuccess()

    }
    const handleAddEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'ADD'})
        setLoadingFlag(false)
        onAddEditHistoryProjectError()
    }

    const handleUpdateEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        const newData = data.data
        dataHisotyProjectList.forEach((item:any)=>{
            if(item.editHistoryId == newData.editHistoryId){
                item.content = newData.content
                item.editDatetime = newData.editDatetime
                item.editor = newData.editor
                item.subject = newData.subject
            }
        })
        gridRef.current.setRowData(dataHisotyProjectList);
        setDataHisotyProjectList(dataHisotyProjectList)
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setIdEditHistory(undefined)
        setLoadingFlag(false)
        onUpdateEditHistoryProjectSuccess()
    }
    const handleUpdateEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setIdEditHistory(undefined)
        setLoadingFlag(false)
        onUpdateEditHistoryProjectError()
    }

    const handleDelete = (data: any) => {
        const msg = "ID: " + data.subject + "を削除します。"
        setTitle("情報削除"); 
        setMessage(msg);
        setIdEditHistory(data.editHistoryId)
        setOpenDeleteDialog(true);
    }
    
    const handleShowDetailPage = (data: any) => {
        setIdEditHistory(data.editHistoryId)
        setDataHistoryProjectAdd({editHistoryId:data.editHistoryId,subject:data.subject,content:data.content,editDatetime:data.editDatetime,editor:data.editor})
        setOpenHistoryProjectAddDialog({isOpen:true,mode:'UPDATE'})

    }

    const handleDeleteOK =() => {
        const resquest = {
            "requestPrms":{
                "userId": user?.userId,
                "projectId": projectData.projectId,
                "editHistoryId":idEditHistory
            }
        }
        onDeleteEditHistoryProject(resquest)
        setLoadingFlag(true)

    }
    const handleDeleteCancel = () => {
        setOpenDeleteDialog(false);
    }

    const handleDeleteEditHistoryProjectSuccess =(success:any, data:any, error:any) =>{
        const dataList = dataHisotyProjectList.filter((item:any)=> item.editHistoryId != data.data)
        gridRef.current.setRowData(dataList);
        setDataHisotyProjectList(dataList)
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setIdEditHistory(undefined)
        setOpenDeleteDialog(false);
        setLoadingFlag(false)
        onDeleteEditHistoryProjectSuccess()

    }
    const handleDeleteEditHistoryProjectError =(success:any, data:any, error:any) =>{
        setIdEditHistory(undefined)
        setOpenHistoryProjectAddDialog({isOpen:false,mode:'UPDATE'})
        setLoadingFlag(false)
        onDeleteEditHistoryProjectError()
    }

    //#endregion HISTORY PROJECT
    // キャンセル
    const handleCancel = () => {
        if (location.key !== "default")
            navigate(-1);
        else
            navigate(RoutePath.ManufactureList);
    }

    // Submitイベント
    const handleEditProjectSubmit = (value: ProjectModel) => {
        if(notAllowEdit){
            notAllowEditLockedProject()
        }
        else{
            setLoadingFlag(true)
            onUpdateProject({
                requestPrms:{
                    userId: user?.userId,
                    ...value,
                    isShared: enableProject,
                    ownerNameProject: ownerNameProject
                }
            });   
        }

    }

    // Submit後のAPI呼び出し成功時
    const handleUpdateProjectSuccess = (success: boolean, data: any, error: any) => {
        if(!enableProject){
            setDataShareProjectList([])
        }
        onUpdateProjectSuccess();
        if(projectData.isShared != enableProject){
            if (location.key !== "default")
                navigate(-1);
            else
                navigate(RoutePath.ManufactureList);
        }
        
        setLoadingFlag(false)
    }

    // Submit後のAPI呼び出しエラー時
    const handleUpdateProjectError = (success: boolean, data: any, error: any) => {
        onUpdateProjectError();
        setLoadingFlag(false);
    }

    //入力欄背景
    const divStyles = {
        backgroundColor: 'white',
        border: '1px solid #bebebe',
        boxShadow: '0 0 5px #fff, 0 0 5px #bebebe, 0 0 1px #aaa',
        padding: '20px 40px',
    }

    const handleEnableProject =( enable:boolean) =>{
        setEnableProject(enable)
        if(!enable){
            handleOpenShareProject(false)
        }
    }

    return (
        <>
            {loadingFlag && (
                <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                    <CircularProgress />
                </div>
            )}

            <Stack>
                <MainHeader user={user} modeProject/>
                <div style={{ height: '100%', width: '100%' }}>
                    <Stack direction="row" justifyContent={"space-between"} mt={2} mx={3}>
                        <Stack alignItems="center" style={{ color: 'MenuText' }}>
                            <Typography variant="h5">
                                プロジェクト設定
                            </Typography>
                        </Stack>
                        <Stack alignItems="center" style={{ color: 'MenuText' }}>
                            <Button variant="contained" onClick={handleCancel}><ListAltSharpIcon />プロジェクト一覧</Button>
                        </Stack>
                    </Stack>
                    <form onSubmit={handleSubmit(handleEditProjectSubmit)}>
                        <TabPanel value={selectTab} index={0} >
                            <div style={divStyles}>
                                <Stack sx={{maxHeight: "calc(100vh)", overflow: 'auto', padding: '8px 0' }}>
                                    <Grid container>
                                        <Grid item md={6} sm={12}>
                                            <Stack>
                                                <Controller
                                                    name="projectName"
                                                    control={control}
                                                    render={({ field }:any) => (
                                                        <TextField
                                                            {...field}
                                                            fullWidth
                                                            type="text"
                                                            label="プロジェクト名"
                                                            size="small"
                                                            disabled={viewOnlyTitleProjects || user?.expired}
                                                        />
                                                    )}
                                                />
                                            </Stack>
                                            <Stack mt={0.5}>
                                                <FormControlLabel
                                                    control={
                                                        <Controller
                                                            name='deleteProjectOnExport'
                                                            control={control}
                                                            render={({ field }) => (
                                                                <Checkbox
                                                                    {...field}
                                                                    disabled={viewOnlyTitleProjects || user?.expired}
                                                                    size="small"
                                                                    style={{ padding: '4px 4px 4px 8px' }}
                                                                    checked={field.value}
                                                                    onChange={(e) => setValue('deleteProjectOnExport', e.target.checked)}
                                                                />
                                                            )}
                                                        />
                                                    }
                                                    label={
                                                        <Typography
                                                            variant="body2"
                                                            color={(viewOnlyTitleProjects || user?.expired)?'grey':'black'}
                                                        >エクスポートする際にプロジェクトを削除する
                                                        </Typography>
                                                    }
                                                />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    {!disablesharedPermissionEditAndSee &&
                                        <Stack width={"50%"} mt={2}>
                                            <Typography variant="body1">プロジェクトの共有</Typography>
                                            <Stack flexDirection={"row"}>
                                                <ButtonGroup disabled={user?.expired}>
                                                    <Button variant={"outlined"} sx={{fontWeight:"bold",color:enableProject?'#4899ea':'Gray',backgroundColor:enableProject?'#e7f3fd':'white'}}  onClick={() =>handleEnableProject(true)}>有効</Button>
                                                    <Button variant={"outlined"} sx={{fontWeight:"bold", color:!enableProject?'#4899ea':'Gray',backgroundColor:!enableProject?'#e7f3fd':'white'}}  onClick={() =>handleEnableProject(false)}>無効</Button>
                                                </ButtonGroup>
                                            </Stack>
                                        </Stack>
                                    }
                                        <Stack direction={"row"} mt={2}>
                                            <Button sx={{border:'1px solid #cccc',fontWeight:"bold",borderRadius:0,color:!backgroundcolorHistoryBt?'#4899ea':'Gray',backgroundColor:!backgroundcolorHistoryBt?'#e7f3fd':'white'}} onClick={() => handleOpenShareProject(false)}>編集履歴</Button>
                                            {(enableProject && !disablesharedPermissionEditAndSee) && <Button sx={{border:'1px solid #cccc',fontWeight:"bold",borderRadius:0,color:backgroundcolorHistoryBt?'#4899ea':'Gray',backgroundColor:backgroundcolorHistoryBt?'#e7f3fd':'white'}} onClick={() => handleOpenShareProject(true)}>共有先</Button>}
                                        </Stack>
                                        {!openShareProject &&
                                            <fieldset style={{border:'0.5px, solid #cccc'}}>
                                                {!viewOnlySharedProjects &&
                                                    <Box display='flex' justifyContent='flex-end'>
                                                        <Stack direction='row' mb={1} mt={1} mr={2}>
                                                            <Button 
                                                                variant="contained" 
                                                                startIcon={
                                                                    <Add style={{fontSize:'24px', width: 50, paddingLeft:'10px'}}/>
                                                                }
                                                                sx={{height: '32px', padding: 0,width:50}}
                                                                onClick={(e) => handleOpenHistoryProjectAddDialog(e)}
                                                                disabled={user?.expired}
                                                            ></Button>
                                                        </Stack>
                                                    </Box>
                                                }
                                                <Stack ml={2} mr={2} mb={2} mt={viewOnlySharedProjects?2:0}>
                                                    <HistoryProjectGrid
                                                        ref={gridRef}
                                                        colState={colState}
                                                        onShowDetails={handleShowDetailPage}
                                                        onDelete={handleDelete}
                                                        dataHisotyProjectList={dataHisotyProjectList}
                                                        heightForm="560"
                                                        checkEdit={true}
                                                        viewOnlySharedProjects ={viewOnlySharedProjects || user?.expired}
                                                    />
                                                    {openDeleteDialog ?
                                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteOK} onCancel={handleDeleteCancel} /> :
                                                    <div />
                                                    }
                                                </Stack>
                                            </fieldset>
                                        }
                                        {openShareProject && enableProject && !disablesharedPermissionEditAndSee &&
                                            <fieldset style={{border:'0.5px, solid #cccc'}} disabled={notAllowEdit}>
                                                <Box display='flex' justifyContent='flex-end' >
                                                    <Stack direction='row' mb={1} mt={1} spacing={1} mr={2}>
                                                        <Button variant="contained" 
                                                            startIcon={
                                                                <Add style={{fontSize:'24px', width: 50, paddingLeft:'10px'}} />
                                                            }
                                                            sx={{height: '32px', padding: 0,width:50}}
                                                            onClick={(e) =>handleOpenShareProjectAdd(e)}
                                                            disabled={notAllowEdit || user?.expired}
                                                        ></Button>
                                                        <Button variant="contained" size="small" onClick={(e) =>handleOpenShareProjectEdit(e)} disabled={isDisableEditShareProject || user?.expired}>{<EditIcon />}</Button>
                                                        <Button variant="contained" size="small" disabled={isDisableDeleteShareProject || user?.expired} onClick={handleDeleteShareProject}>{<ClearIcon />} </Button>
                                                    </Stack>
                                                </Box>
                                                <Stack ml={2} mr={2} mb={2}>
                                                    <ShareProjectGrid
                                                        ref={gridRefShareProject}
                                                        colState={colState}
                                                        // onDelete={handleDelete}
                                                        onSelect={handleSelectShareProject}
                                                        heightForm="560"
                                                        checkEdit={true}
                                                        dataShareProjectList ={dataShareProjectList}
                                                    />
                                                    {openDeleteShareProject ?
                                                        <AlertDialog title={title} message={message} isOpen={true} onOK={handleDeleteShareProjectOK} onCancel={handleDeleteShareProjectCancel} /> :
                                                    <div />
                                                    }
                                                </Stack>
                                            </fieldset>
                                        }
                                </Stack>
                            </div>
                        </TabPanel>
                        <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 0, mr: 3 ,mb:2}}>
                            <LoadingButton variant="contained" type="submit" loading={loadingFlag} disabled={disablesharedPermissionEditAndSee || user?.expired}>保存</LoadingButton>
                            <Button color="inherit" variant="contained" onClick={handleCancel}>キャンセル</Button>
                        </Stack>
                    </form>
                </div>
            </Stack>
            
            {openShareProjectDialog.isOpen && <ShareProjectDialog listUser ={listUser} dataShareProjectList ={dataShareProjectList} data={dataOpenShareProject} mode={openShareProjectDialog.mode}  onCancel={() => setOpenShareProjectDialog({isOpen: false, mode: "ADD"})} onOK={handleOkShareProject} />}
            <FetchHelper fetchId={UPDATE_PROJECT} onComplete={(success, data, error) => success ? handleUpdateProjectSuccess(success, data, error) : handleUpdateProjectError(success, data, error)} />
            <FetchHelper fetchId={ADD_SHARED_USER} onComplete={(success, data, error) => success ? handleAddSharedUserSuccess(success, data, error) : handleAddSharedUserError(success, data, error)} />
            <FetchHelper fetchId={EDIT_SHARED_USER} onComplete={(success, data, error) => success ? handleEditSharedUserSuccess(success, data, error) : handleEditSharedUserError(success, data, error)} />
            <FetchHelper fetchId={DELETE_SHARED_USER} onComplete={(success, data, error) => success ? handleDeleteSharedUserSuccess(success, data, error) : handleDeleteSharedUserError(success, data, error)} />
            <FetchHelper fetchId={"GET_PROJECT"} onComplete={(success, data, error) => success ? handleGetProjectSuccess(success, data, error) : handleGetProjectError(success, data, error)} />
            <FetchHelper fetchId={GET_SHARE_PROJECT_LIST} onComplete={(success, data, error) => success ? handleGetProjectSuccess(success, data, error) : handleGetProjectError(success, data, error)} />

            {/*HISTORY PROJECT*/}
            {openHistoryProjectAddDialog.isOpen && <HistoryProjectAddDialog viewOnlySharedProjects ={viewOnlySharedProjects || user?.expired} data={dataHistoryProjectAdd} onCancel={()=>setOpenHistoryProjectAddDialog({isOpen:false,mode:'ADD'})} onOK={handleHistoryProjectAddDialog}></HistoryProjectAddDialog>}
            <FetchHelper fetchId={ADD_EDIT_HISTORY} onComplete={(success, data, error) => success ? handleAddEditHistoryProjectSuccess(success, data, error) : handleAddEditHistoryProjectError(success, data, error)} />
            <FetchHelper fetchId={UPDATE_EDIT_HISTORY} onComplete={(success, data, error) => success ? handleUpdateEditHistoryProjectSuccess(success, data, error) : handleUpdateEditHistoryProjectError(success, data, error)} />
            <FetchHelper fetchId={DELETE_EDIT_HISTORY} onComplete={(success, data, error) => success ? handleDeleteEditHistoryProjectSuccess(success, data, error) : handleDeleteEditHistoryProjectError(success, data, error)} />


        </>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        onUpdateProject: (params: any) => dispatch(actionCreators.fetch(UPDATE_PROJECT, `/project/edit`, 'POST', params, false, true)),
        onGetProject: (params: any) => dispatch(actionCreators.fetch("GET_PROJECT", `/project/get`, 'POST', params, false, true)),
        //SHARE PROJECT
        onGetShareProjectList: (params: any) => dispatch(actionCreators.fetch(GET_SHARE_PROJECT_LIST, '/project/get-share-project', 'POST', params, false, true)),
        onAddSharedUser: (params: any) => dispatch(actionCreators.fetch(ADD_SHARED_USER, `/project/add-shared-user`, 'POST', params, false, true)),
        onEditSharedUser: (params: any) => dispatch(actionCreators.fetch(EDIT_SHARED_USER, `/project/edit-shared-user`, 'POST', params, false, true)),
        onDeleteSharedUser: (params: any) => dispatch(actionCreators.fetch(DELETE_SHARED_USER, `/project/delete-shared-user`, 'POST', params, false, true)),
        //HISTORY PROJECT
        onAddEditHistoryProject: (params: any) => dispatch(actionCreators.fetch(ADD_EDIT_HISTORY, `/project/add-edit-history`, 'POST', params, false, true)),
        onUpdateEditHistoryProject: (params: any) => dispatch(actionCreators.fetch(UPDATE_EDIT_HISTORY, `/project/update-edit-history`, 'POST', params, false, true)),
        onDeleteEditHistoryProject: (params: any) => dispatch(actionCreators.fetch(DELETE_EDIT_HISTORY, `/project/delete-edit-history`, 'POST', params, false, true)),


        notAllowEditLockedProject: () =>
            dispatch(actionCreators.showMessage({
                type: "warning",
                title: "編集ロック中です",
                body: "編集ロックが取得されているため、プロジェクトの共有を無効にできません。",
            })),

        onUpdateProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "プロジェクト編集",
                body: "プロジェクトを更新しました。",
            })),
        onUpdateProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "プロジェクト編集",
                body: "プロジェクトを更新出来ませんでした。",
            })),

        //HISTORY PROJECT
        onAddEditHistoryProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "編集履歴追加",
                body: "編集履歴を登録しました。",
            })),
        onAddEditHistoryProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "編集履歴追加",
                body: "編集履歴が登録出来ませんでした。",
            })),

        onUpdateEditHistoryProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "編集履歴編集",
                body: "編集履歴を更新しました。",
            })),
        onUpdateEditHistoryProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "編集履歴編集",
                body: "編集履歴を更新出来ませんでした。",
            })),
        onDeleteEditHistoryProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "編集履歴削除",
                body: "編集履歴を削除しました。",
            })),
        onDeleteEditHistoryProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "編集履歴削除",
                body: "編集履歴が削除出来ませんでした。",
            })),

        //SHARE PROJECT
        onAddShareProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "共有先追加",
                body: "共有先を登録しました。",
            })),
        onAddShareProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "共有先追加",
                body: "共有先追加が登録出来ませんでした。",
            })),

        onEditShareProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "共有先編集",
                body: "共有先を更新しました。",
            })),
        onEditShareProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "共有先編集",
                body: "共有先を更新出来ませんでした。",
            })),
        onDeleteShareProjectSuccess: () =>
            dispatch(actionCreators.showMessage({
                type: "info",
                title: "共有先削除",
                body: "共有先を削除しました。",
            })),
        onDeleteShareProjectError: () =>
            dispatch(actionCreators.showMessage({
                type: "error",
                title: "共有先削除",
                body: "共有先が削除出来ませんでした。",
            })),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectSetting as any);


