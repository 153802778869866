import axios from "axios";
import { AdjustElements, CdPoint, OneCurve, PartDetailsModel, keyable } from "../../models/Index"
import { SessionExpiredAction, actionCreators } from "../../store/AppStore";
import { RS_CURVE_CENTER, RS_CURVE_CURRENT, RS_CURVE_MAX, RS_CURVE_MIN, RS_CURVE_MULTIPLE, RS_DIAL_CONTINUE, RS_DIAL_FIXED, RS_DIAL_MIX } from "../../models/Constants";
import { point, Polygon } from "@flatten-js/core";
import { groupBy } from "../../utils/groupBy";
import { Auth } from "aws-amplify";
import {store} from "../../"
import { redirectMaintenancePage } from "../CallApi";

const doReadAllCurveInfos = async (plistInfoPart : PartDetailsModel[], isFuse : boolean, drawThreeCurve : boolean) => {
    const curveInfosOfAllParts : any[] = []
    // each part has 1 curve info
    // each curve info has a list of readCurves

    // example: HVCB has 2 parts, part[0] has charDialId is 4820, part[1] has ...
    // with charDialId 4820, we have a list of 10 readCurves group by l_curve_headerid (from 794 -> 803)
    for (let index = 0; index < plistInfoPart.length; index++) {
        const pInfoPart = plistInfoPart[index];
        
        if (isFuse && drawThreeCurve) {
            const listOfDialCharFUSE : any[] = []
            // for each charDial (contain multi readCurves)
            for (let index = 0; index < pInfoPart.charDialList.length; index++) {
                const charDial = pInfoPart.charDialList[index];
                const temp = await doReadCurveInfos(charDial.value)
                const listOfReadCurves : any[] = []

                 // format the return list
                 const groups = groupBy(temp, 'lCurveHeaderId')
                
                Object.values(groups).forEach((value : any) => {
                    let readCurve :  { listReadPoints : any[] } = { listReadPoints: [] }
                    value.forEach((element : any) => {
                        readCurve.listReadPoints.push({m_dx: element.m_dx, m_dy: element.m_dy})
                        delete element.m_dx
                        delete element.m_dy
                        readCurve = {
                            ...element,
                            ...readCurve,
                        }
                    });
                    listOfReadCurves.push(readCurve)
                })

                listOfDialCharFUSE.push(listOfReadCurves)
            }

            // need to group by l_curve_headerid at (backend | frondend) before push into curveInfosOfAllParts
            curveInfosOfAllParts.push(listOfDialCharFUSE)
        } else {
            const { charDialID : m_lDialCharID } = pInfoPart
            const temp = await doReadCurveInfos(m_lDialCharID)
            const listOfReadCurves : any[] = []

            // format the return list
            const groups = groupBy(temp, 'lCurveHeaderId')
            
            Object.values(groups).forEach((value : any) => {
                let readCurve :  { listReadPoints : any[] } = { listReadPoints: [] }
                value.forEach((element : any) => {
                    readCurve.listReadPoints.push({m_dx: element.m_dx, m_dy: element.m_dy})
                    delete element.m_dx
                    delete element.m_dy
                    readCurve = {
                        ...element,
                        ...readCurve,
                    }
                });
                listOfReadCurves.push(readCurve)
            })

            // need to group by l_curve_headerid at (backend | frondend) before push into curveInfosOfAllParts
            curveInfosOfAllParts.push(listOfReadCurves)
        }
    }
    
    return curveInfosOfAllParts
}

const doReadCurveInfos = async (lDialCharID : number) => {
    const m_listReadCurve = await doReadByDialCharID(lDialCharID);
    return m_listReadCurve
}

const doReadByDialCharID = async (lDialCharID : number) => {
    // TODO: handle when error occurs
    let token = ""
    try {
        const session = await Auth.currentSession()
        token = session.getIdToken().getJwtToken()
    } catch (error) {
        store.dispatch({
            type: "SESSION_EXPIRED",
        } as SessionExpiredAction);
    }
    try {
        const res = await axios.post(`${process.env.REACT_APP_APIGW_URL}/graph/get-curveinfo`, {
            l_dial_charid: lDialCharID,
            elementType: "GRAPH_INFO"
        }, {
            headers: {
                'Authorization': token
            }
        })
        
        return res.data
    } catch (err: any) {
        if (err.response && err.response.status === 503){
            redirectMaintenancePage(err.response);
        }
        console.error(err)
    }
}

const doCalcSetAllOneCurve = (nCurveKind : number, props : AdjustElements, readCurves : any, infoPart: PartDetailsModel, dDelayTime: number, m_listCalc : OneCurve[]) => {

    let bRet = false

    // One part detail could have many readCurves (list of readCurves)
    // Loop over list of readCurves
    // START LOOP
    readCurves.forEach((readCurve : any) => {
        // if match some condition -> continue to next loop without calc curve point
        if (readCurve.nCurveKind !== nCurveKind) {
            return; // move to next loop
        }
        if (readCurve.bSaveFormula === true) {
            return; // move to next loop
        }
        // if match some condition -> continue to next loop without calc curve point
        if (infoPart.readPart && infoPart.readPart.isNoneSadamu === true) {
            const dTimeValue = infoPart.timeValue
            if(infoPart.timeFlag === RS_DIAL_FIXED) {
                // const dTimeValue = Number(infoPart.timeList.find((time : any) => time.value === infoPart.timeValue)?.label)
                const dPairTime = readCurve.dPairTime
                if (dTimeValue !== dPairTime) return;
            } else if (infoPart.timeFlag === RS_DIAL_CONTINUE){
                // const dTimeValue = Number(infoPart.timeList.find((time : any) => time.value === infoPart.timeValue)?.label)
                const dStdTimeValue = readCurve.dStdTimeValue
                if (dTimeValue !== dStdTimeValue) return;
            }
        }
        
        const newOneCurve = doAddNewOneCurve()

        newOneCurve.m_bSetLock = infoPart.setLock

        // when do not match above conditions, calc set point of this readCurve
        doCalcSetAllPoints(nCurveKind, props, readCurve, infoPart, dDelayTime, m_listCalc, newOneCurve)

        newOneCurve.m_dSaturationTime = readCurve.dSaturationTime + dDelayTime;
        
        //TODO: 瞬時値を整定値換算してセットする for caculate InstValue
		//pOneCurve->DoCalcSetInstValue(nCurveKind, pReadCurve, lVoltage, pInfoBase, pReadBase,pInfoPart, pReadPart, this);

        // when we has draw some lines, set return flag to true
        bRet = true
    });
    // END LOOP

    if (bRet || infoPart.timeFlag !== RS_DIAL_CONTINUE) return bRet

    let curve1index = 0;
    let curve2index = 0;

    //            // 時間ダイアルが不定則のときで時間値に一致する曲線が見つからなかったときに
    //            // 時間値の直近上位の曲線を見つけ出して、整定値換算して曲線を作成する
    bRet = false;
    let dSaveStdTimeValue1 = 0;  // 直近上位の基準電流値
    let posCurve1;      // 直近上位の曲線位置
    let posSave1;
    for (let iloop1 = 0; iloop1 < readCurves.length; ++iloop1)
    {
        posSave1 = readCurves[iloop1];

        const pReadCurve = readCurves[iloop1];
        if (pReadCurve.nCurveKind !== nCurveKind)
        {
            continue;
        }
        if (pReadCurve.bSaveFormula === true)
        {
            continue;
        }
        const dTimeValue = (infoPart.timeValue);
        const dStdTimeValue = pReadCurve.dStdTimeValue;
        if (dTimeValue > dStdTimeValue)
        {
            continue;
        }
        if (dSaveStdTimeValue1 === 0)
        {
            dSaveStdTimeValue1 = dStdTimeValue;
            posCurve1 = posSave1;
            continue;
        }
        if (dSaveStdTimeValue1 < dStdTimeValue)
        {
            continue;
        }
        dSaveStdTimeValue1 = dStdTimeValue;
        posCurve1 = posSave1;

        curve1index = iloop1;
    }

    let dSaveStdTimeValue2 = 0;  // 直近下位の基準電流値
    let posCurve2;      // 直近下位の曲線位置
    let posSave2;
    for (let loop2 = readCurves.length - 1; loop2 > 0; loop2--)
    {
        posSave2 = readCurves[loop2];

        const readCurve2 = readCurves[loop2];
        if (readCurve2.nCurveKind !== nCurveKind)
        {
            continue;
        }
        if (readCurve2.bSaveFormula === true)
        {
            continue;
        }

        const dTimeValue = infoPart.timeValue;
        const dStdTimeValue = readCurve2.dStdTimeValue;
        if (dTimeValue < dStdTimeValue)
        {
            continue;
        }
        if (dSaveStdTimeValue2 === 0)
        {
            dSaveStdTimeValue2 = dStdTimeValue;
            posCurve2 = posSave2;
            continue;
        }
        if (dSaveStdTimeValue2 > dStdTimeValue)
        {
            continue;
        }
        dSaveStdTimeValue2 = dStdTimeValue;
        posCurve2 = posSave2;

        curve2index = loop2;
    }
    let readCurve = null;
    // 連続可変の場合は計算で曲線を作成する
    if (dSaveStdTimeValue1 > 0 && dSaveStdTimeValue2 === 0)
    {
        readCurve = readCurves[curve1index];
    }
    else if (dSaveStdTimeValue2 > 0 && dSaveStdTimeValue1 === 0)
    {
        readCurve = readCurves[curve2index];
    }
    else if (dSaveStdTimeValue1 > 0 && dSaveStdTimeValue2 > 0)
    {
        let dDifference = dSaveStdTimeValue1 - dSaveStdTimeValue2;
        dDifference = dDifference / (infoPart.timeValue - dSaveStdTimeValue2);
        if (dDifference < 3)
        {
            readCurve = readCurves[curve1index];
        }
        else
        {
            readCurve = readCurves[curve2index];
        }
    }

    if (readCurve !== null)
    {       // 座標を整定値換算して描画用座標リストにセットする
        const pOneCurve = doAddNewOneCurve()
        doCalcSetAllPoints(nCurveKind, props, readCurve, infoPart, dDelayTime, m_listCalc, pOneCurve);
        pOneCurve.m_dSaturationTime = readCurve.m_dSaturationTime;
        bRet = true;
    }

    return bRet;
}

const doCalcSetErrorCurves = (nCurveKind : number, props : AdjustElements, readCurves : any, infoPart: PartDetailsModel, dDelayTime: number, m_listCalc : any[]) => {
    let bRet = false

    readCurves.forEach((readCurve : any) => {
        // if match some condition -> continue to next loop without calc curve point
        if (
            readCurve.dXErrorPlus < 0 &&
            readCurve.dXErrorMinus < 0 &&
            readCurve.dYErrorPlus < 0 &&
            readCurve.dYErrorMinus < 0
        ) {
            return; // move to next loop
        }
        if (readCurve.nCurveKind !== RS_CURVE_CENTER) {
            return; // move to next loop
        }
        // if match some condition -> continue to next loop without calc curve point
        if (infoPart.readPart?.isNoneSadamu) {
            if(infoPart.timeFlag === RS_DIAL_FIXED) {
                const dTimeValue = infoPart.timeValue
                // const dTimeValue = Number(infoPart.timeList.find((time : any) => time.value === infoPart.timeValue)?.label)
                const dPairTime = readCurve.dPairTime
                if (dTimeValue !== dPairTime) return;
            } else if (infoPart.timeFlag === RS_DIAL_CONTINUE){
                return;
            }
        }

        // TODO: get bDispband
        const bDispBand = true
        switch (nCurveKind) {
            case RS_CURVE_MAX: {
                if (bDispBand) {
                    nCurveKind = RS_CURVE_MAX
                } else {
                    nCurveKind = RS_CURVE_CENTER
                }

                const newOneCurve = doAddNewOneCurve()
                // when do not match above conditions, calc set point of this readCurve
                doCalcSetAllPoints(nCurveKind, props, readCurve, infoPart, dDelayTime, m_listCalc, newOneCurve)

                // TODO: calc ....

                bRet = true
                break;
            }
            case RS_CURVE_MIN: {
                if (bDispBand) {
                    nCurveKind = RS_CURVE_MIN
                } else {
                    nCurveKind = RS_CURVE_CENTER
                }

                const newOneCurve = doAddNewOneCurve()
                // when do not match above conditions, calc set point of this readCurve
                doCalcSetAllPoints(nCurveKind, props, readCurve, infoPart, dDelayTime, m_listCalc, newOneCurve)

                // TODO: calc ....

                bRet = true
                break;
            }
        }
        // when we has draw some lines, set return flag to true
        bRet = true
    });   
}

const doAddNewOneCurve = () => {
    return {
        m_bSetLock: false,
        m_dInstValue: 0,
        m_dMultiValue: 1,
        m_dSaturationTime: 0,
        m_listdPoint: []
    }
}
 
const doCalcSetAllPoints = (nCurveKind : number, props : AdjustElements, readCurve : any, infoPart : PartDetailsModel, dDelayTime : number, m_listCalc : OneCurve[], newOneCurve : OneCurve) => {
   
    newOneCurve.m_dMultiValue = 1

    readCurve.listReadPoints.forEach((point : CdPoint) => {
        const calcPoint : CdPoint = {...point}
        switch(nCurveKind) {
            case RS_CURVE_MAX:
                if (readCurve.dXErrorPlus >= 0) {
                    calcPoint.m_dx = point.m_dx + point.m_dx * readCurve.dXErrorPlus / 100
                }
                if (readCurve.dYErrorPlus >= 0) {
                    calcPoint.m_dy = point.m_dy + point.m_dy * readCurve.dYErrorPlus / 100
                }
                break;
            case RS_CURVE_MIN:
                if (readCurve.dXErrorMinus >= 0) {
                    calcPoint.m_dx = point.m_dx - point.m_dx * readCurve.dXErrorMinus / 100
                }
                if (readCurve.dYErrorMinus >= 0) {
                    calcPoint.m_dy = point.m_dy - point.m_dy * readCurve.dYErrorMinus / 100
                }
                break;
        }

        switch(readCurve.nSettingFlag) {
            case RS_CURVE_MULTIPLE:
                // m_dx
                newOneCurve.m_dMultiValue = doCalcSetMultiple(calcPoint, props, infoPart, readCurve, m_listCalc)
                break
            case RS_CURVE_CURRENT:
                // m_dx
                newOneCurve.m_dMultiValue = doCalcSetCurrent(calcPoint, props, infoPart, readCurve, m_listCalc)
                break;
        }
        
        // m_dy
        doCalcSetTimePoint(calcPoint, props, infoPart, readCurve)
        calcPoint.m_dy += dDelayTime

        newOneCurve.m_listdPoint.push(calcPoint)
    })

    m_listCalc.push(newOneCurve)
}

const doCalcSetCurrent = (point : CdPoint, props : AdjustElements, infoPart : PartDetailsModel, readCurve : any, m_listCalc : OneCurve[]) => {
    const infoBase = props.details.base
    const readBase = infoBase.readBase
    const readPart = infoPart.readPart

    let dMultiValue = 1
    let dCurrentMulti = 1

    if (infoBase.useCT) {
        if (infoBase.ctPrimary > 0 && infoBase.ctSecondary > 0) {
            if (readPart?.isLink && readPart.linkNumber && readPart.linkNumber > 0) { //do nothing
            } else {
                dCurrentMulti = infoBase.ctPrimary / infoBase.ctSecondary
                dMultiValue = infoBase.ctPrimary / infoBase.ctSecondary
            }
        }
    }
    
    if (readBase.canUseCC && infoBase.useCC) {
        if (infoBase.turnNumber && infoBase.turnNumber > 0) {
            dMultiValue /= infoBase.turnNumber
        }
    }

    if (readCurve.dStdCurrentValue > 0) {
        dMultiValue /= readCurve.dStdCurrentValue
    }

    if (readPart?.isLink) {
        if (readPart.linkNumber === 0) { 
            switch(readBase?.ratedFlag) {
                case RS_DIAL_FIXED:
                case RS_DIAL_CONTINUE:
                case RS_DIAL_MIX:
                    dMultiValue *= infoBase.ratedValue
                    break;
            }
        }
    } else {
        dMultiValue *= readCurve.dStdCurrentValue
    }

    switch(readBase?.magniFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            dMultiValue *= infoBase.magniValue
            break;
    }

    switch(infoPart.currentFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            const dCurrentValue = Number(infoPart.currentValue)
            if (readPart?.isCurrent) {
                dCurrentMulti *= dCurrentValue
                dMultiValue *=  dCurrentValue
                if (infoBase.ratedValue > 0) { //TODO: readBase.ratedCurrent > 0
                    if (readPart.linkNumber === 0 && !readPart.isLink) {
                        dCurrentMulti /= readCurve.dStdCurrentValue / infoBase.ratedValue //TODO: readBase.ratedCurrent
                        dMultiValue /= readCurve.dStdCurrentValue / infoBase.ratedValue 
                    }
                    if (readPart.linkNumber === 0 || !readPart.isLink) {
                        dCurrentMulti /= infoBase.ratedValue //TODO: readBase.ratedCurrent
                        dMultiValue /= infoBase.ratedValue
                    }
                }
            } else {
                if (readPart?.isLink && readPart.linkNumber && readPart.linkNumber > 0) {
                    const pOneCurve = m_listCalc[readPart.linkNumber - 1]
                    if (pOneCurve) {
                        dCurrentMulti *= pOneCurve.m_dMultiValue * dCurrentValue / 100
                        dMultiValue *= pOneCurve.m_dMultiValue * dCurrentValue / 100
                        dMultiValue *= infoBase.ratedValue //TODO: readBase.ratedCurrent
                    }
                } else {
                    dCurrentMulti *= dCurrentValue / 100  
                    dMultiValue *=  dCurrentValue / 100 
                }
            }
            break;
    }

    switch(infoPart.magniCurrentFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            if (readPart?.isLink) {
                dCurrentMulti *= infoPart.magniCurrentValue
            }
            dMultiValue *= infoPart.magniCurrentValue
            break;
    }

    point.m_dx = dMultiValue * point.m_dx * props.voltage
    // point.m_dx = dMultiValue * point.m_dx 
    
    return dCurrentMulti
}

const doCalcSetMultiple = (point : CdPoint, props : AdjustElements, infoPart : PartDetailsModel, readCurve : any, m_listCalc : OneCurve[]) => {
    //TODO check again
    const infoBase = props.details.base
    const readBase = infoBase.readBase
    const readPart = infoPart.readPart

    let dMultiValue = 1
    let dCurrentMulti = 1

    if (infoBase.useCT) {
        if (infoBase.ctPrimary > 0 && infoBase.ctSecondary > 0) {
            if (readPart?.isLink && readPart.linkNumber && readPart.linkNumber > 0) { //do nothing
            } else {
                dCurrentMulti = infoBase.ctPrimary / infoBase.ctSecondary
                dMultiValue = infoBase.ctPrimary / infoBase.ctSecondary
            }
        }
    }
    
    if (readBase.canUseCC && infoBase.useCC) {
        if (infoBase.turnNumber && infoBase.turnNumber > 0) {
            dMultiValue /= infoBase.turnNumber
        }
    }

    if (readCurve.dStdCurrentValue > 0) {
        dMultiValue *= 100 / readCurve.dStdCurrentValue
    }

    if (readPart?.isLink) {
        if (readPart.linkNumber === 0) { 
            switch(readBase?.ratedFlag) {
                case RS_DIAL_FIXED:
                case RS_DIAL_CONTINUE:
                case RS_DIAL_MIX:
                    dMultiValue *= infoBase.ratedValue / 100
                    break;
            }
        }
    } else {
        const ratedCurrent = infoPart.currentValue? infoPart.currentValue : infoBase.ratedValue??0// TODO: readBase.ratedCurrent-->part.currentValue
        dMultiValue *=  ratedCurrent / 100
    }

    switch(readBase?.magniFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            dMultiValue *= infoBase.magniValue
            break;
    }

    switch(infoPart.currentFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            const dCurrentValue = Number(infoPart.currentValue)
            if (readPart?.isCurrent) {
                dCurrentMulti *= dCurrentValue / 100
                dMultiValue *=  dCurrentValue / 100
            } else {
                if (readPart?.isLink && readPart.linkNumber && readPart.linkNumber > 0) {
                    const pOneCurve = m_listCalc[readPart.linkNumber - 1]
                    if (pOneCurve) {
                        dCurrentMulti *= pOneCurve.m_dMultiValue * dCurrentValue / 100
                        dMultiValue *= pOneCurve.m_dMultiValue * dCurrentValue / 100
                        dMultiValue *= infoPart.currentValue? infoPart.currentValue : infoBase.ratedValue??0//infoBase.ratedValue // TODO: readBase.ratedCurrent
                    }
                } else {
                    dCurrentMulti *= dCurrentValue / 100
                    dMultiValue *=  dCurrentValue / 100
                }
            }
            break;
    }

    switch(infoPart.magniCurrentFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            if (readPart?.isLink) {
                dCurrentMulti *= infoPart.magniCurrentValue
            }
            dMultiValue *= infoPart.magniCurrentValue
            break;
    }

    point.m_dx = dMultiValue * point.m_dx * props.voltage
    // point.m_dx = dMultiValue * point.m_dx 
    
    return dCurrentMulti
}

const doCalcSetTimePoint = (point : CdPoint, props : AdjustElements, infoPart : PartDetailsModel, readCurve : any) => {
    const infoBase = props.details.base
    const readBase = infoBase.readBase
    const readPart = infoPart.readPart
    
    let dMultiValue = 1

    switch(infoPart.timeFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            if (readCurve.dStdTimeValue > 0) {
                //const timeValue = infoPart.timeValue
                dMultiValue = dMultiValue * infoPart.timeValue / readCurve.dStdTimeValue
            }
            else {
                dMultiValue = 1
            }
            break;
    }

    switch(infoPart.magniTimeFlag) {
        case RS_DIAL_FIXED:
        case RS_DIAL_CONTINUE:
            if (readCurve.dStdTimeValue > 0) {
                dMultiValue = dMultiValue * infoPart.magniTimeValue / readCurve.dStdTimeValue
            } else {
                dMultiValue = 1;
            }
            break;
    }

    point.m_dy = point.m_dy * dMultiValue
}

const doMakeOneCurve = (listPointsFinal : CdPoint[], pListOneCurve : OneCurve[]) => {
    let nCount = 0;
    let listLength = pListOneCurve.length
    let pos = 0
    while (pos < listLength) {
        let posPrev = pos
        const pCurveNow = pListOneCurve[pos++];

        let ppNextExist : CdPoint | undefined  
        if (pos < listLength) { 
            let posNextExist = pos
            const pCurveNextExist = pListOneCurve[posNextExist++] //curve next exist

            if (!pCurveNextExist.m_bSetLock && pCurveNextExist.m_listdPoint.length > 0) {
                ppNextExist = pCurveNextExist.m_listdPoint[0] //get head
            }
        }

        if (pCurveNow.m_bSetLock || (
            doCheckSkip(pCurveNow.m_listdPoint, listPointsFinal) && nCount === 0
        )) {
// 202410
            // if (ppNextExist !== undefined || pCurveNow.m_bSetLock === true)
// 202410
            {
                continue;
            }
        }

        let pCurveNext : OneCurve | undefined;
        let ppNext : CdPoint | undefined;
        let ppLast : CdPoint | undefined;
        if (pos < listLength) {
            let posNext = pos
            pCurveNext = pListOneCurve[posNext++]
            if (pCurveNext.m_bSetLock !== true && pCurveNext.m_listdPoint.length > 0) {
                ppNext = pCurveNext.m_listdPoint[0]
                ppLast = pCurveNext.m_listdPoint[pCurveNext.m_listdPoint.length - 1]
            }
        }

        let pCurvePrev : OneCurve | undefined;
        let ppPrev : CdPoint | undefined;
        let ppTail : CdPoint | undefined;
        posPrev--;
// 202410
        // if (posPrev >= 0) {
        while (posPrev >= 0) {
// 202410
            pCurvePrev = pListOneCurve[posPrev--]
            if (pCurvePrev.m_bSetLock !== true && pCurvePrev.m_listdPoint.length > 0) {
                const length = pCurvePrev.m_listdPoint.length
                if (pCurvePrev.m_listdPoint[length - 1]) {
                    ppTail = pCurvePrev.m_listdPoint[length - 1]
                }
                if (pCurvePrev.m_listdPoint[length - 2]) {
                    ppPrev = pCurvePrev.m_listdPoint[length - 2]
                }
            }
// 202410
            if (ppPrev === undefined || ppTail === undefined){
                pCurvePrev = undefined;
            } else {
                break;
            }
// 202410
        }

        let listSavePoint : CdPoint[] = []
        const nRectifies = doRectifiesPoints1(pCurveNow.m_listdPoint, ppNext, listSavePoint, listPointsFinal)
        if (nRectifies === 2) {
            listSavePoint = []
            continue
        }

        const ppointCross : CdPoint | undefined = doCombinatePoints(pCurveNow.m_listdPoint, listPointsFinal)

        let bCross = false
        if (pCurvePrev !== undefined) {
            if (nCount === 0) {
                switch(nRectifies) {
                    case 1:
                        let listTarPoint : CdPoint[] = []
                        pCurvePrev?.m_listdPoint.forEach((point : CdPoint) => listTarPoint.push({...point})) //copy all point
                        listTarPoint = doDeleteCrossLine(ppointCross, listTarPoint)

                        bCross = doRectifiesPoints4(listTarPoint, listPointsFinal)
                        break;
                    case 0:
                        bCross = doRectifiesPoints3(pCurvePrev.m_listdPoint, listPointsFinal)
                        break;
                }
                nCount++;
            }
        }

        if (bCross !== true) {
            //v2.93
            //if (nRectifies === 1 && nCount > 0) {
            if ( ( nRectifies == 1 || nRectifies == 3) && nCount > 0) {
                let listTarPoint : CdPoint[] = []
                listSavePoint.forEach((point : CdPoint) => listTarPoint.push({...point})) //copy all points
                listTarPoint = doDeleteCrossLine(ppointCross, listTarPoint)

                bCross = doRectifiesPoints4(listTarPoint, listPointsFinal)
            }
            doCombinatePoints(listSavePoint, listPointsFinal)
            doRectifiesPoints2(ppPrev, ppTail, ppNext, ppLast, listPointsFinal);
        }

    }
}

// この要素が必要かどうかをチェックする
const doCheckSkip = (pListPoint : CdPoint[], listPointsFinal : CdPoint[]) => {
    if (listPointsFinal.length === 0) return false

    // 比較もと曲線の領域を作成する
    const polygon1 = new Polygon();
    const count1 = listPointsFinal.length;
    const shapes1 = [];
    for (let i = 0; i < count1; i++) {
        const cdPoint : CdPoint = listPointsFinal[i];
        const tmp = point(cdPoint.m_dx, cdPoint.m_dy)
        shapes1[i] = tmp
    }
    shapes1[count1] = point(
        shapes1[count1 - 1].x + 10000000,
        shapes1[count1 - 1].y
    )
    shapes1[count1 + 1] = point(
        shapes1[count1 - 1].x + 10000000,
        -10000000
    )
    shapes1[count1 + 2] = point(
        shapes1[0].x,
        -10000000
    )

    
    // 比較さきの曲線の領域を作成する
    const polygon2 = new Polygon();
    const count2 = pListPoint.length;
    const shapes2 = [];
    for (let i = 0; i < count2; i++) {
        const cdPoint : CdPoint = pListPoint[i];
        const tmp = point(cdPoint.m_dx, cdPoint.m_dy)
        shapes2[i] = tmp
    }
    shapes2[count2] = point(
        shapes2[count2 - 1].x,
        10000000
    )
    shapes2[count2 + 1] = point(
        shapes2[0].x,
        10000000
    )
    
    polygon1.addFace(shapes1);
    polygon2.addFace(shapes2);
    const intersectPoints = polygon2.intersect(polygon1);

    if (intersectPoints.length > 0) {
        // 領域が交わっているとき
        return false
    }
    return true
}

const doRectifiesPoints1 = (pListPoint : CdPoint[], ppNext : CdPoint | undefined, pListSavePoint : CdPoint[], listPointsFinal : CdPoint[]) => {
    let nRet = 0;

	if (pListPoint.length === 0) return nRet
    let pos2 = 0;
    const ppHead = pListPoint[pos2++]
    const ppHead2 = pos2 < pListPoint.length ? pListPoint[pos2++] : undefined
    
    const ppTail = pListPoint[pListPoint.length - 1]

    let nCount = listPointsFinal.length
    let ppEnd : CdPoint | undefined
    let ppPrev : CdPoint | undefined

    let pos1 = listPointsFinal.length - 1
	while (pos1 >= 0) {
		ppEnd = ppPrev;
		ppPrev = listPointsFinal[pos1--]
		if (ppEnd === undefined) continue

		
		if (ppHead.m_dy >= ppEnd.m_dy) {
			if (doRectifiesPoints4(pListPoint, listPointsFinal) == true) {
				return 2;
			}
		}

		if (ppHead.m_dx < ppPrev.m_dx) {		// ①の処理
            listPointsFinal.splice(listPointsFinal.indexOf(ppEnd), 1)
            pListSavePoint.unshift(ppEnd)
            continue
		}
		if (ppPrev.m_dx <= ppHead.m_dx && ppHead.m_dx < ppEnd.m_dx) {	// ②の処理
			const dRatioY = (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01)) / (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
			const dMinusY = dRatioY * (Math.log10(ppHead.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
			let dStdYUp = Math.log10(ppPrev.m_dy / 0.01) - dMinusY;
            dStdYUp = (10 ** dStdYUp) * 0.01
			if (ppHead.m_dy < dStdYUp) {		// ②Ａの処理
                //v2.93
				// if (ppPrev.m_dx == ppHead.m_dx) {
				// 	listPointsFinal.splice(listPointsFinal.indexOf(ppEnd), 1)
				// 	//delete ppEnd;
                //     //nRet = 1;
				// 	break;
				// }
				ppEnd.m_dy = dStdYUp;
				ppEnd.m_dx = ppHead.m_dx;
				//nRet = 1;
				nRet = 3;
			}
			else {								// ②Ｂの処理
				if (ppEnd.m_dy < ppTail.m_dy) {
					break;
				}
				if (ppPrev.m_dy == ppEnd.m_dy) {
					listPointsFinal.splice(listPointsFinal.indexOf(ppEnd), 1)
					//delete ppEnd;
					continue;
				}
				if (pListSavePoint.length > 0 && ppPrev.m_dy !== ppEnd.m_dy) {
					let posSave = 0;
					while (posSave < pListSavePoint.length) {
						let posDel = posSave;
                        const pdPointSave : CdPoint = pListSavePoint[posSave];
                        posSave++;
                        listPointsFinal.push(pdPointSave);
                        pListSavePoint.splice(posDel, 1);
					}
                    let posNow = listPointsFinal.length - 1;
                    ppEnd = listPointsFinal[posNow];
                    posNow--;
                    ppPrev = listPointsFinal[posNow];
                    posNow--;
				}

				let dRatioX = 0;
				let dMinusX = 0;
				let dNext : CdPoint;
				let dEnd : CdPoint;
				if (pListPoint[0].m_dx > ppEnd.m_dx) {
					// CONVERT
                    dRatioX = (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10)) / (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01));
                    dMinusX = dRatioX * (Math.log10(ppTail.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01));
                    dNext = {...ppEnd};
                    let dStdXDown = Math.log10(dNext.m_dx / 10) - dMinusX
                    dStdXDown = (10 ** dStdXDown) * 10
                    dNext.m_dx = dStdXDown;
                    dNext.m_dy = ppTail.m_dy;
                    dEnd = {...ppEnd};
				}
				else {
					dNext = {...ppEnd};
                    dEnd = {...ppPrev};
				}
				let pdCross : CdPoint | undefined
                let ppHead3 : CdPoint | undefined
                let ppNext3 : CdPoint | undefined
                let pos3 = 0;
				while (pos3 < pListPoint.length) {			// ⑦の処理
                    ppHead3 = ppNext3;
                    ppNext3 = pListPoint[pos3++];
                    if (ppHead3 == null)
                    {
                        continue;
                    }                           // 次の要素の曲線との交差点を探す
                    pdCross = doGetCrossPoint(ppHead3, ppNext3, dEnd, dNext);
                    if (pdCross != null)
                    {
                        break;
                    }
				}
				if (pdCross != null) {
					ppEnd.m_dx = pdCross.m_dx;
                    ppEnd.m_dy = pdCross.m_dy;
                    //delete pdCross;
                    nRet = 1;
                    break;
				}
				if (pListPoint[0].m_dx > ppEnd.m_dx) {
                    ppEnd.m_dx -= dMinusX;
                    ppEnd.m_dy = ppTail.m_dy;
				}
				if (ppNext != null) {			// 次の要素の開始座標があるとき
					 // CONVERT
                     let dRatioY3 = (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01)) / (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
                     let dMinusY3 = dRatioY3 * (Math.log10(ppNext.m_dx / 10) - Math.log10(ppEnd.m_dx / 10));
                     let dStdYDown = Math.log10(ppEnd.m_dy / 0.01) - dMinusY3;
                     dStdYDown = (10 ** dStdYDown) * 0.01
                     if (dStdYDown <= ppTail.m_dy)
                     {
                         ppEnd.m_dy = dStdYDown;
                         ppEnd.m_dx = ppNext.m_dx;
                     }
                     nRet = 1;
				}
				else {
                    let pdPoint : CdPoint = {m_dx: 0, m_dy : 0}
                    pdPoint.m_dx = ppEnd.m_dx + 10000;
                    pdPoint.m_dy = ppEnd.m_dy;
                    listPointsFinal.push(pdPoint);
                    if (doRectifiesPoints4(pListPoint, listPointsFinal) == true)
                    {
                        nRet = 2;
                    }
                    else
                    {
                        listPointsFinal.splice(listPointsFinal.indexOf(pdPoint), 1)
                        nRet = 1;
                    }
				}
			}
			break;
		}
		if (ppHead.m_dx >= ppEnd.m_dx) {		// ③の処理
			if (ppEnd.m_dx - ppPrev.m_dx == 0) {
				ppEnd.m_dy = ppPrev.m_dy;
				ppEnd.m_dx = ppHead.m_dx;
				nRet = 1;
				break;
			}
			const dRatioY = (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01)) / (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
			const dMinusY = dRatioY * (Math.log10(ppHead.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
			let dStdYUp = Math.log10(ppPrev.m_dy / 0.01) - dMinusY;
            dStdYUp = (10 ** dStdYUp) * 0.01
			if (ppHead.m_dy < dStdYUp) {		// ③Ａの処理
				ppEnd.m_dy = dStdYUp;
				ppEnd.m_dx = ppHead.m_dx;
				nRet = 1;
			}
			else {								// ③Ｂの処理
				if (ppEnd.m_dy < ppTail.m_dy) {
					break;
				}
				if (ppPrev.m_dy == ppEnd.m_dy) {
					if (ppEnd.m_dy <= ppHead.m_dy) {
						ppEnd.m_dx = ppHead.m_dx;
						break;
					}
                    listPointsFinal.splice(listPointsFinal.indexOf(ppEnd), 1)
					//delete ppEnd;
					continue;
				}
				if (pListSavePoint.length > 0) {
					break;
				}

				let dRatioX = 0;
				let dMinusX = 0;
				let dNext : CdPoint;
				let dEnd : CdPoint;
				if (pListPoint[0].m_dx > ppEnd.m_dx) {
					dRatioX = (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10)) / (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01));
					dMinusX = dRatioX * (Math.log10(ppTail.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01));
					dNext = {...ppEnd};
                    let dStdXDown = Math.log10(dNext.m_dx / 10) - dMinusX
                    dStdXDown = (10 ** dStdXDown) * 10
                    dNext.m_dx = dStdXDown;
					dNext.m_dy = ppTail.m_dy;
					dEnd = {...ppEnd};
				}
				else {
					dNext = {...ppEnd};
					dEnd = {...ppPrev};
				}
                let pdCross : CdPoint | undefined
                let ppHead3 : CdPoint | undefined
                let ppNext3 : CdPoint | undefined
				let pos3 = 0;
				while (pos3 < pListPoint.length) {			// ⑦の処理
					ppHead3 = ppNext3;
                    ppNext3 = pListPoint[pos3++];
                    if (ppHead3 == null)
                    {
                        continue;
                    }                           // 次の要素の曲線との交差点を探す
                    pdCross = doGetCrossPoint(ppHead3, ppNext3, dEnd, dNext);
                    if (pdCross != null)
                    {
                        break;
                    }
				}
				if (pdCross != null) {
                    ppEnd.m_dx = pdCross.m_dx;
                    ppEnd.m_dy = pdCross.m_dy;
                    //delete pdCross;
                    nRet = 1;
                    break;
				}
				if (pListPoint[0].m_dx > ppEnd.m_dx) {
                    ppEnd.m_dx -= dMinusX;
                    ppEnd.m_dy = ppTail.m_dy;
				}
				if (ppNext != null) {			// 次の要素の開始座標があるとき
					 // CONVERT
                     let dRatioY3 = (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01)) / (Math.log10(ppEnd.m_dx / 10) - Math.log10(ppPrev.m_dx / 10));
                     let dMinusY3 = dRatioY3 * (Math.log10(ppNext.m_dx / 10) - Math.log10(ppEnd.m_dx / 10));
                     let dStdYDown = Math.log10(ppEnd.m_dy / 0.01) - dMinusY3;
                     dStdYDown = (10 ** dStdYDown) * 0.01
                     if (dStdYDown <= ppTail.m_dy)
                     {
                         ppEnd.m_dy = dStdYDown;
                         ppEnd.m_dx = ppNext.m_dx;
                     }
                     nRet = 1;
				}
				else {
                    let pdPoint : CdPoint = {m_dx: 0, m_dy : 0}
                    pdPoint.m_dx = ppEnd.m_dx + 10000;
                    pdPoint.m_dy = ppEnd.m_dy;
                    listPointsFinal.push(pdPoint);
                    if (doRectifiesPoints4(pListPoint, listPointsFinal) == true)
                    {
                        nRet = 2;
                    }
                    else
                    {
                        listPointsFinal.splice(listPointsFinal.indexOf(pdPoint), 1)
                        nRet = 1;
                    }
				}
			}
			break;
		}
	}
	if (nCount === pListSavePoint.length + 1) {
		 // remove
         ppPrev && listPointsFinal.splice(listPointsFinal.indexOf(ppPrev), 1)
         // addhead
         ppPrev && pListSavePoint.unshift(ppPrev)
	}
	return nRet;
}

const doRectifiesPoints2 = (ppPrev : CdPoint | undefined, ppTail : CdPoint | undefined, ppNext : CdPoint | undefined, ppLast : CdPoint | undefined, listPointsFinal : CdPoint[]) => {
    if (ppTail === undefined || ppPrev === undefined || ppNext === undefined || ppLast === undefined) {
        return
    }

    // CONVERT
    let dRatioX = 0
    if (ppPrev.m_dy - ppTail.m_dy !== 0) {
        dRatioX = (Math.log10(ppTail.m_dx / 10) - Math.log10(ppPrev.m_dx / 10)) / (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppTail.m_dy / 0.01))
    }
    let dRatioY = 0
    if (ppTail.m_dx - ppPrev.m_dx !== 0) {
        dRatioY = (Math.log10(ppPrev.m_dy / 0.01) - Math.log10(ppTail.m_dy / 0.01)) / (Math.log10(ppTail.m_dx / 10) - Math.log10(ppPrev.m_dx / 10)) 
    }

    const dMinusY = dRatioY * (Math.log10(ppNext.m_dx / 10) - Math.log10(ppTail.m_dx / 10))
    let dStdYDown = Math.log10(ppTail.m_dy / 0.01) - dMinusY
    dStdYDown = (10 ** dStdYDown) * 0.01
    const pStd : CdPoint = {
        m_dx: ppNext.m_dx,
        m_dy: dStdYDown
    }

    let ppEnd : CdPoint | undefined
    let ppBef : CdPoint | undefined
    let pos = listPointsFinal.length - 1
    while (pos >= 0) {
        ppEnd = ppBef
        ppBef = listPointsFinal[pos--]

        if (ppEnd === undefined) continue
        if (ppEnd.m_dy <= pStd.m_dy) break;
        if (ppEnd.m_dy <= ppBef.m_dy) {
            ppEnd.m_dy += (ppBef.m_dy - ppEnd.m_dy) / 2
            const dMinusX = dRatioX * (Math.log10(ppTail.m_dy / 0.01) - Math.log10(ppEnd.m_dy / 0.01))
            let dStdXUp = Math.log10(ppTail.m_dx / 10) + dMinusX
            dStdXUp = (10 ** dStdXUp) * 10
            ppEnd.m_dx = dStdXUp

            if (pStd.m_dy < ppLast.m_dy) {
                break;
            }

            const pNew : CdPoint = {...pStd}
            listPointsFinal.push(pNew)
            break;
        }
        if (ppEnd.m_dx >= pStd.m_dx) {
            // remove
            listPointsFinal.splice(listPointsFinal.indexOf(ppEnd), 1)
            // delete
        }

    }
}

const doRectifiesPoints3 = (pListPoint : CdPoint[], listPointsFinal : CdPoint[]) => {
    if (pListPoint.length == 0){
        return false;
    }

    let pdCross : CdPoint | undefined
    let ppEnd1 : CdPoint | undefined
    let ppPrev1 : CdPoint | undefined
    let pos1 = listPointsFinal.length- 1;
    while (pos1 >= 0)
    {
        ppEnd1 = ppPrev1;
        ppPrev1 = listPointsFinal[pos1];
        pos1--;
        if (ppEnd1 == null)
        {
            continue;
        }
        if (ppPrev1.m_dx == ppEnd1.m_dx)
        {
            break;
        }
        let ppEnd2 : CdPoint | undefined
        let ppPrev2 : CdPoint | undefined
        let pos2 = pListPoint.length - 1;
        while (pos2 >= 0)
        {
            ppEnd2 = ppPrev2;
            ppPrev2 = pListPoint[pos2];
            pos2--;

            if (ppEnd2 == null)
            {
                continue;
            }                           // 交差点が絶対に見つからないとき
            if (ppPrev2.m_dy > ppPrev1.m_dy && ppEnd2.m_dy > ppPrev1.m_dy)
            {
                break;
            }
            pdCross = doGetCrossPoint(ppPrev2, ppEnd2, ppPrev1, ppEnd1);
            if (pdCross != null)
            {
                break;
            }
        }
        if (pdCross != null)
        {
            break;
        }
    }
    if (pdCross != null)
    {
        pos1 = listPointsFinal.length - 1;
        while (pos1 >= 0)
        {
            let posDel = pos1;
            const pdPoint = listPointsFinal[pos1];
            pos1--;

            if (pdPoint.m_dx <= pdCross.m_dx)
            {
                listPointsFinal.push(pdCross);       // 取得した交差点座標を追加する
                break;
            }
            listPointsFinal.splice(posDel, 1);           // 最後から交差点までの座標を削除して

        }
        let pos2 = 0;
        while (pos2 < pListPoint.length)
        {
            let pdPoint2 = pListPoint[pos2++];

            if (pdPoint2.m_dy >= pdCross.m_dy)
            {
                continue;
            }
            const pdPoint1 : CdPoint =       // 交差点以下の座標を追加する
            {
                m_dx: pdPoint2.m_dx,
                m_dy: pdPoint2.m_dy
            };
            //pdPoint1 = pdPoint2;
            listPointsFinal.push(pdPoint1);
        }
        return true;
    }
    return false;
}

const doRectifiesPoints4 = (pListPoint : CdPoint[], listPointsFinal : CdPoint[]) => {

    if (pListPoint.length <= 1) {
        return false
    }

    let pdCross : CdPoint | undefined
    let ppHead1 : CdPoint | undefined
    let ppNext1 : CdPoint | undefined

    let pos1 = 0
    while (pos1 < listPointsFinal.length) {
        ppHead1 = ppNext1
        ppNext1 = listPointsFinal[pos1++]
        if (ppHead1 === undefined) continue

        let ppHead2 : CdPoint | undefined
        let ppNext2 : CdPoint | undefined
        let pos2 = 0

        while(pos2 < pListPoint.length) {
            ppHead2 = ppNext2
            ppNext2 = pListPoint[pos2++]
            if (ppHead2 === undefined) continue

            if (ppHead1.m_dx === ppHead2.m_dx && ppHead1.m_dy === ppHead2.m_dy &&
                ppNext1.m_dx === ppNext2.m_dx && ppNext1.m_dy === ppNext2.m_dy) {
                    break
                }

            if (ppHead1.m_dx === ppNext2.m_dx && ppHead1.m_dy === ppNext2.m_dy) {
                break;
            }

            if (ppNext1.m_dx === ppHead2.m_dx && ppNext1.m_dy === ppHead2.m_dy) {
                break;
            }

            pdCross = doGetCrossPoint(ppHead2, ppNext2, ppHead1, ppNext1)
            if (pdCross !== undefined) {
                //v2.93
                if( pdCross.m_dx == ppHead1.m_dx && pdCross.m_dy == ppHead1.m_dy )
                {
                    pdCross = undefined //delete
                    break;
                }
                const dx1 = ppHead2.m_dx - ppNext2.m_dx
                const dy1 = ppHead2.m_dy - ppNext2.m_dy
                const dx2 = ppHead1.m_dx - ppNext1.m_dx
                const dy2 = ppHead1.m_dy - ppNext1.m_dy
                if ((dx1 !== 0 && dx2 !== 0) && (dy1 / dx1 > dy2 / dx2)) {
                    pdCross = undefined //delete ???
                    return false
                }
                break;
            }
        }
        if (pdCross !== undefined) {
            break;
        }
    }
    if (pdCross !== undefined) {
        let listDelete : CdPoint[] = []
        let pos1 = listPointsFinal.length - 1;
        while (pos1 >= 0) {
            let posDel = pos1;
            const pdPoint : CdPoint = listPointsFinal[pos1];
            pos1--;
            if (pdPoint.m_dx <= pdCross.m_dx) {
                if (pdPoint.m_dx == pdCross.m_dx && pdPoint.m_dy <= pdCross.m_dy) {
                    continue;
                }
                listPointsFinal.push(pdCross);           // 取得した交差点座標を追加する
                const pdDelete : CdPoint = 
                {
                    m_dx : pdPoint.m_dx,
                    m_dy : pdPoint.m_dy
                };
                //pdDelete = pdPoint;
                listDelete.unshift(pdDelete);   // 別のところに保存する
                break;
            }
            listPointsFinal.splice(posDel, 1);               // 最後から交差点までの座標を削除して
            listDelete.unshift(pdPoint);    // 別のところに保存する
        }
        let pos2 = 0;
        while (pos2 < pListPoint.length)
        {
            const pdPoint2 = pListPoint[pos2];
            pos2++;
            if (pdPoint2.m_dy >= pdCross.m_dy)
            {
                continue;
            }
            const pdPoint1 : CdPoint =  // 交差点以下の座標を追加する
            {
                m_dx : pdPoint2.m_dx,
                m_dy : pdPoint2.m_dy
            };
            //pdPoint1 = pdPoint2;         
            listPointsFinal.push(pdPoint1);
        }
        // 曲線の交差点までの部分の座標を削除する
        listDelete = doDeleteCrossLine(pdCross, listDelete);
        if (doRectifiesPoints4(listDelete, listPointsFinal) != true)
        {
            const pdTail : CdPoint = listPointsFinal[listPointsFinal.length - 1];
            const pdPoint : CdPoint =
            {
                m_dx : pdTail.m_dx + 10000,
                m_dy : pdTail.m_dy
            };
            listPointsFinal.push(pdPoint);

            const listPointNew : CdPoint[] = []
            let pdTail2 : CdPoint = {m_dx : 0, m_dy : 0}
            let pdTail1 : CdPoint = {m_dx : 0, m_dy : 0}
            let posNew = listPointsFinal.length - 1; ;
            pdTail1.m_dx = listPointsFinal[posNew].m_dx;
            pdTail1.m_dy = listPointsFinal[posNew].m_dy;
            posNew--;
            pdTail2.m_dx = listPointsFinal[posNew].m_dx;
            pdTail2.m_dy = listPointsFinal[posNew].m_dy;
            posNew--;

            listPointNew.push(pdTail2);
            listPointNew.push(pdTail1);

            if (doRectifiesPoints4(listDelete, listPointNew) == true)
            {
                posNew = listPointsFinal.length - 1;
                // let posDel = posNew;
                listPointsFinal.splice(posNew, 1); //WARNING
                listPointsFinal.push(...listPointNew);

            }
            else
            {
                listPointsFinal.splice(listPointsFinal.indexOf(pdPoint), 1)
                //delete pdPoint;
            }
        }
        return true;
    }
    return false
}


const doCombinatePoints = (pListPoint : CdPoint[], listPointsFinal : CdPoint[]) => {
    const ppTail : CdPoint | undefined = listPointsFinal.length > 0 ? listPointsFinal[listPointsFinal.length - 1] : undefined
    
    pListPoint.forEach((ppointCalc : CdPoint) => {
        if (ppTail !== undefined &&
                (ppointCalc.m_dy > ppTail.m_dy || ppointCalc.m_dx < ppTail.m_dx)
            ) {
                return
        }
        // const ppointDraw = ppointCalc   
        listPointsFinal.push({...ppointCalc})
    })

    return ppTail
}

const doDeleteCrossLine = (ppointCross : CdPoint | undefined, plistTarPoint : CdPoint[]) => {
    if (ppointCross === undefined) return plistTarPoint

    const delElements : CdPoint[] = []
    plistTarPoint.every((pdPoint : CdPoint) => {
        if (pdPoint.m_dx < ppointCross.m_dx) {
            delElements.push(pdPoint)
            return true
        }
        else if (pdPoint.m_dx !== ppointCross.m_dx &&
                pdPoint.m_dy !== ppointCross.m_dy
            ) {
                plistTarPoint.unshift({...ppointCross})
        }
        return false
    })

    return plistTarPoint.filter((point : CdPoint) => !delElements.includes(point))
}

const doGetCrossPoint = (pdTopLeft1 : CdPoint | undefined, pdBottomRight1 : CdPoint | undefined, pdTopLeft2 : CdPoint | undefined, pdBottomRight2 : CdPoint | undefined) => {

    if (!pdBottomRight1 || !pdBottomRight2 || !pdTopLeft1 || !pdTopLeft2) return undefined

    if (pdTopLeft1.m_dx > pdBottomRight1.m_dx) return undefined
    if (pdTopLeft2.m_dx > pdBottomRight2.m_dx) return undefined

    if (pdBottomRight1.m_dx < pdTopLeft2.m_dx) return undefined
    if (pdBottomRight1.m_dy > pdTopLeft2.m_dy) return undefined
    
    if (pdBottomRight2.m_dx < pdTopLeft1.m_dx) return undefined
    if (pdBottomRight2.m_dy > pdTopLeft1.m_dy) return undefined

    if (pdTopLeft1.m_dx == pdBottomRight1.m_dx && pdTopLeft1.m_dx == pdTopLeft2.m_dx &&
        pdTopLeft2.m_dy <= pdTopLeft1.m_dy && pdTopLeft2.m_dy >= pdBottomRight1.m_dy)
    {
        // 交差するパターン1
        const ppointFCross1 : CdPoint =
        {
            m_dx : pdTopLeft2.m_dx,
            m_dy : pdTopLeft2.m_dy
        };
        return ppointFCross1;
    }
    if (pdTopLeft1.m_dx == pdBottomRight1.m_dx && pdTopLeft1.m_dx == pdBottomRight2.m_dx &&
        pdBottomRight2.m_dy <= pdTopLeft1.m_dy && pdBottomRight2.m_dy >= pdBottomRight1.m_dy)
    {
        // 交差するパターン2
        const ppointFCross2 : CdPoint =
        {
            m_dx : pdBottomRight2.m_dx,
            m_dy : pdBottomRight2.m_dy
        };
        return ppointFCross2;
    }
    if (pdTopLeft1.m_dy == pdBottomRight1.m_dy && pdTopLeft1.m_dy == pdTopLeft2.m_dy &&
        pdTopLeft2.m_dx >= pdTopLeft1.m_dx && pdTopLeft2.m_dx <= pdBottomRight1.m_dx)
    {
        // 交差するパターン3
        const ppointFCross3 : CdPoint =
        {
            m_dx : pdTopLeft2.m_dx,
            m_dy : pdTopLeft2.m_dy
        };
        return ppointFCross3;
    }
    if (pdTopLeft1.m_dy == pdBottomRight1.m_dy && pdTopLeft1.m_dy == pdBottomRight2.m_dy &&
        pdBottomRight2.m_dx >= pdTopLeft1.m_dx && pdBottomRight2.m_dx <= pdBottomRight1.m_dx)
    {
        // 交差するパターン4
        const ppointFCross4 : CdPoint =
        {
            m_dx : pdBottomRight2.m_dx,
            m_dy : pdBottomRight2.m_dy
        };
        return ppointFCross4;
    }

    const ppointFCross = isIntersectionLines(pdTopLeft1, pdBottomRight1, pdTopLeft2, pdBottomRight2);

    return ppointFCross;
}

// 座標 ppointF1,ppointF2 を結ぶ線分と座標 ppointF3,ppointF4 を結ぶ線分が交差しているかを調べる
// ただし、線分が重なっている場合(4点が一直線上にある)、「交差していない」、と判定します。
const isIntersectionLines = (sourcePpointF1 : CdPoint, sourcePpointF2 : CdPoint, sourcePpointF3 : CdPoint, sourcePpointF4 : CdPoint) => {
    let ppointF1 = {...sourcePpointF1}, ppointF2 = {...sourcePpointF2}, ppointF3 = {...sourcePpointF3}, ppointF4 = {...sourcePpointF4}
    // ppointF1.m_dx = Math.log10(ppointF1.m_dx / 10)
    // ppointF1.m_dy = Math.log10(ppointF1.m_dy / 0.01)
    // ppointF2.m_dx = Math.log10(ppointF2.m_dx / 10)
    // ppointF2.m_dy = Math.log10(ppointF2.m_dy / 0.01)
    // ppointF3.m_dx = Math.log10(ppointF3.m_dx / 10)
    // ppointF3.m_dy = Math.log10(ppointF3.m_dy / 0.01)
    // ppointF4.m_dx = Math.log10(ppointF4.m_dx / 10)
    // ppointF4.m_dy = Math.log10(ppointF4.m_dy / 0.01)

    //v2.93
    const EPSILON = 1 * Math.pow(10, -9);

    let x1 = ppointF1.m_dx, y1 = ppointF1.m_dy;
    let x2 = ppointF2.m_dx, y2 = ppointF2.m_dy;
    let x3 = ppointF3.m_dx, y3 = ppointF3.m_dy;
    let x4 = ppointF4.m_dx, y4 = ppointF4.m_dy;

    let dx1 = x2 - x1, dy1 = y2 - y1;
    let dx2 = x4 - x3, dy2 = y4 - y3;

    let denom = dx1 * dy2 - dy1 * dx2;
    
    if (Math.abs(denom) < EPSILON) {
        // 線分が平行または重なる場合
        return undefined;
    }

    let ua = ((x3 - x1) * dy2 - (y3 - y1) * dx2) / denom;
    let ub = ((x3 - x1) * dy1 - (y3 - y1) * dx1) / denom;

    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
        let intersection : CdPoint = 
        {
            m_dx : x1 + ua * dx1,
            m_dy : y1 + ua * dy1
        };
        return intersection;
    }

    return undefined;

    // let dYRatio1 = 0;
    // if (ppointF2.m_dx - ppointF1.m_dx != 0)
    // {
    //     dYRatio1 = (ppointF1.m_dy - ppointF2.m_dy) / (ppointF2.m_dx - ppointF1.m_dx);
    // }
    // let dXRatio1 = 0;
    // if (ppointF1.m_dy - ppointF2.m_dy != 0)
    // {
    //     dXRatio1 = (ppointF2.m_dx - ppointF1.m_dx) / (ppointF1.m_dy - ppointF2.m_dy);
    // }
    // let dYRatio2 = 0;
    // if (ppointF4.m_dx - ppointF3.m_dx != 0)
    // {
    //     dYRatio2 = (ppointF3.m_dy - ppointF4.m_dy) / (ppointF4.m_dx - ppointF3.m_dx);
    // }

    // // それぞれの点と点の差
    // let dx1 = ppointF1.m_dx - ppointF2.m_dx;
    // let dy1 = ppointF1.m_dy - ppointF2.m_dy;
    // let dx2 = ppointF3.m_dx - ppointF4.m_dx;
    // let dy2 = ppointF3.m_dy - ppointF4.m_dy;
    // // どちらもy軸に平行な線分ではない
    // if (dx1 != 0 && dx2 != 0)
    // {
    //     // 平行な2線分ではない
    //     if ((dy1 / dx1 - dy2 / dx2) != 0)
    //     {
    //         // 交点を求める
    //         let dX = (ppointF3.m_dy - ppointF1.m_dy - ppointF3.m_dx * dy2 / dx2 + ppointF1.m_dx * dy1 / dx1) / (dy1 / dx1 - dy2 / dx2);
    //         // その交点が線分の範囲にあるか
    //         if (((dX < ppointF1.m_dx && dX > ppointF2.m_dx) || (dX > ppointF1.m_dx && dX < ppointF2.m_dx)) &&
    //             ((dX < ppointF3.m_dx && dX > ppointF4.m_dx) || (dX > ppointF3.m_dx && dX < ppointF4.m_dx)))
    //         {
    //             let dY = 0;
    //             if (ppointF3.m_dx <= ppointF1.m_dx)
    //             {
    //                 // Ａのとき
    //                 dY = ppointF3.m_dy - (dX - ppointF3.m_dx) * dYRatio2;
    //             }
    //             else if (ppointF3.m_dx > ppointF1.m_dx)
    //             {
    //                 // Ｂのとき
    //                 if (dXRatio1 == 0)
    //                 {
    //                     dY = ppointF1.m_dy;
    //                 }
    //                 else
    //                 {
    //                     dY = ppointF3.m_dy - (dX - ppointF3.m_dx) * dYRatio2;
    //                 }
    //             }
    //             // その交点が線分の範囲にあるか
    //             if ((dY < ppointF1.m_dy && dY > ppointF2.m_dy) || (dY > ppointF1.m_dy && dY < ppointF2.m_dy))
    //             {
    //                 // 交点
    //                 const ppointFCross : CdPoint = 
    //                 {
    //                     m_dx : (10 ** dX) * 10,
    //                     m_dy : (10 ** dY) * 0.01
    //                 };
    //                 return ppointFCross;
    //             }
    //             return undefined;
    //         }
    //     }
    // }
    // // p1,p2 を結ぶ線分がy軸に平行である
    // else if (dx1 == 0 && dx2 != 0)
    // {
    //     // 交点を求める
    //     let dY = ppointF1.m_dx * dy2 / dx2 + ppointF3.m_dy - ppointF3.m_dx * dy2 / dx2;
    //     // その交点が線分の範囲にあるか
    //     if (((dY < ppointF1.m_dy && dY > ppointF2.m_dy) || (dY > ppointF1.m_dy && dY < ppointF2.m_dy)) &&
    //         ((ppointF1.m_dx < ppointF3.m_dx && ppointF1.m_dx > ppointF4.m_dx) || (ppointF1.m_dx > ppointF3.m_dx && ppointF1.m_dx < ppointF4.m_dx)))
    //     {
    //         // 交点
    //         const ppointFCross : CdPoint = 
    //         {
    //             m_dx : (10 ** ppointF1.m_dx) * 10,
    //             m_dy : (10 ** dY) * 0.01
    //         };
    //         return ppointFCross;
    //     }
    //     // その交点が線分上にあるか
    //     else if (dY == ppointF3.m_dy && dY == ppointF4.m_dy)
    //     {
    //         // 交点
    //         const ppointFCross : CdPoint = 
    //         {
    //             m_dx : (10 ** ppointF1.m_dx) * 10,
    //             m_dy : (10 ** dY) * 0.01
    //         };
    //         return ppointFCross;
    //     }
    // }
    // // p3,p4 を結ぶ線分がy軸に平行である
    // else if (dx1 != 0 && dx2 == 0)
    // {
    //     // 交点を求める
    //     let dY = ppointF3.m_dx * dy1 / dx1 + ppointF1.m_dy - ppointF1.m_dx * dy1 / dx1;
    //     // その交点が線分の範囲にあるか
    //     if (((dY < ppointF3.m_dy && dY > ppointF4.m_dy) || (dY > ppointF3.m_dy && dY < ppointF4.m_dy)) &&
    //         ((ppointF3.m_dx < ppointF1.m_dx && ppointF3.m_dx > ppointF2.m_dx) || (ppointF3.m_dx > ppointF1.m_dx && ppointF3.m_dx < ppointF2.m_dx)))
    //     {
    //         // 交点
    //         const ppointFCross : CdPoint = 
    //         {
    //             m_dx : (10 ** ppointF3.m_dx) * 10,
    //             m_dy : (10 ** dY) * 0.01
    //         };
    //         return ppointFCross;
    //     }
    //     // その交点が線分上にあるか
    //     else if (dY == ppointF1.m_dy && dY == ppointF2.m_dy)
    //     {
    //         // 交点
    //         const ppointFCross : CdPoint = 
    //         {
    //             m_dx : (10 ** ppointF3.m_dx) * 10,
    //             m_dy : (10 ** dY) * 0.01
    //         };
    //         return ppointFCross;
    //     }
    // }
    // // それ以外は交差しない
    // return undefined;
}

export {
    doReadAllCurveInfos,
    doCalcSetAllOneCurve,
    doCalcSetErrorCurves,
    doMakeOneCurve
}