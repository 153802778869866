import { Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Stack, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Auth } from "aws-amplify";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ApplicationState, actionCreators } from "../../../store";
import FetchHelper from "../../FetchHelper";
import ToastContainer from "../../common/ToastContainer";
import { updateUserInfo } from "../../../store/Actions";

export type UsersListProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const UPDATE_USER = "UPDATE_USER";

interface ItemForm{
    custom_company_name:string;
    family_name:string;
    given_name:string;
    email: string;
    phone_number: string;
    custom_plan: string;
    custom_expiration_start_date: string;
    custom_expiration_end_date: string;
    custom_mfa: string;
    custom_mfa_system:string;
    delete_project_on_export: boolean;
}

export const UserSetting = (props: UsersListProps) => {
    const {user, attribute, onUpdateUsers, updateUserInfo, onUpdateSuccess, onUpdateError} = props;
    let navigate = useNavigate();
    const [loadingFlag,setLoadingFlag] = useState<boolean>(false);
    const [isTypePhone,setIsTypePhone] = useState<boolean>(false);
    const [attributeData] = useState<any>({...attribute});
    const [isRoleAdmin,setIsRoleAdmin] = useState<boolean>(attributeData.cognit_user?.attributes["custom:plan"] == "admin");
    const [isShowMFAMethod,setIsShowMFAMethod] = useState<boolean>(attributeData.cognit_user?.attributes["custom:mfa"] == "true");

    const convert_phone_number = (phone_number:string) => {
        if(phone_number){
            let result = "";
            if(phone_number.startsWith("+81")){
                result = "0";
                result += phone_number.substring(3);
            }else{
                result = phone_number;
            }
            return result;
        }
        return "";
    }

    const initialValue:any = {
        custom_company_name:"",
        family_name:"",
        given_name:"",
        email:"",
        custom_plan:"",
        custom_expiration_start_date: "",
        custom_expiration_end_date: "",
        phone_number:"",
        custom_mfa:"",
        custom_mfa_system:"",
        delete_project_on_export: false,
    };

    const [startDateSelect,setStartDateSelect] = useState<any>();
    const [endDateSelect,setEndDateSelect] = useState<any>();

    const { handleSubmit, formState: { errors }, control, setValue, getValues, setError, clearErrors } = useForm<ItemForm>({
        mode: 'all',
        criteriaMode: "all",
        defaultValues:initialValue
    });

    useEffect(() => {
        setLoadingFlag(true);
        Auth.userAttributes(attributeData.cognit_user)
        .then(async (userAttr: any) => {
            userAttr.forEach((item:any) => {
                switch(item.Name){
                    case "phone_number":
                        if(item.Value){
                            let result = "";
                            if(item.Value.startsWith("+81")){
                                result = "0";
                                result += item.Value.substring(3);
                            }else{
                                result = item.Value;
                            }
                            setValue("phone_number",result);
                            setIsTypePhone(true);
                        }else{
                            setIsTypePhone(false);
                        }
                        break;
                    case "custom:mfa":
                        setValue("custom_mfa",item.Value);
                        if(item.Value == "true"){
                            setIsShowMFAMethod(true);
                        }else{
                            setIsShowMFAMethod(false);
                        }
                        break;
                    case "custom:mfa_system":
                        setValue("custom_mfa_system",item.Value);
                        break;
                    case "custom:company_name":
                        setValue("custom_company_name",item.Value);
                        break;
                    case "family_name":
                        setValue("family_name",item.Value);
                        break;
                    case "given_name":
                        setValue("given_name",item.Value);
                        break;
                    case "email":
                        setValue("email",item.Value);
                        break;
                    case "custom:plan":
                        if(item.Value == "admin"){
                            setIsRoleAdmin(true);
                        }else{
                            setIsRoleAdmin(false);
                        }
                        setValue("custom_plan",item.Value);
                        break;
                    case "custom:expiration_date":
                        if(attributeData.cognit_user.attributes["custom:plan"] != "admin" && (typeof attributeData.cognit_user.attributes["custom:expiration_date"] == 'string' && attributeData.cognit_user.attributes["custom:expiration_date"].length == 17)){
                            setValue("custom_expiration_start_date",attributeData.cognit_user.attributes["custom:expiration_date"].substring(0, 8));
                            setStartDateSelect(dayjs(attributeData.cognit_user.attributes["custom:expiration_date"].substring(0, 8)));
                        }else{
                            setValue("custom_expiration_start_date","");
                            setStartDateSelect(dayjs(""));
                        }
                                        
                        if(attributeData.cognit_user.attributes["custom:plan"] != "admin" && (typeof attributeData.cognit_user.attributes["custom:expiration_date"] == 'string' && attributeData.cognit_user.attributes["custom:expiration_date"].length == 17)){
                            setValue("custom_expiration_end_date",attributeData.cognit_user.attributes["custom:expiration_date"].substring(9, 18));
                            setEndDateSelect(dayjs(attributeData.cognit_user.attributes["custom:expiration_date"].substring(9, 18)));
                        }else{
                            setValue("custom_expiration_end_date","");
                            setEndDateSelect(dayjs(""));
                        }
                        break;
                }                
            })
            setValue("delete_project_on_export", user?.deleteProjectOnExport || false)
        })
        .catch((err: any) => {
            // dispatch(
            //   actionCreators.showMessage({
            //     type: "error",
            //     title: "認証",
            //     body: "認証に失敗しました。",
            //   })
            // );
          }).finally(() => {
            setLoadingFlag(false);
        });
    },[]);


    // FormデータからRequestデータを作成する
    const createRequestData = (formValue: ItemForm) => {
        let request = [{
            cognito_username: formValue.email,
            update_info: {
                custom_management_no: attributeData.cognit_user.attributes["custom:management_no"],
                custom_company_name:formValue.custom_company_name,
                custom_zip_code: attributeData.cognit_user.attributes["custom:zip_code"],
                address:attributeData.cognit_user.attributes["address"],
                family_name: formValue.family_name,
                given_name: formValue.given_name,
                custom_role:attributeData.cognit_user.attributes["custom:role"],
                email:formValue.email,
                phone_number: convert_phone_number(formValue.phone_number),
                custom_expiration_date: isRoleAdmin ? "" : formValue.custom_expiration_start_date.replaceAll("/", "") + "~" + formValue.custom_expiration_end_date.replaceAll("/", ""),
                custom_memo: attributeData.cognit_user.attributes["custom:memo"],
                custom_mfa: formValue.custom_mfa,
                custom_mfa_system: formValue.custom_mfa == "true" ? formValue.custom_mfa_system : "",
                custom_plan: isRoleAdmin ? "admin" : formValue.custom_plan,
                custom_serial_no: attributeData.cognit_user.attributes["custom:serial_no"],
                account_status: attributeData.cognit_user["Enabled"],
            },
            users_table_info: {
                id: user?.userId,
                delete_project_on_export: formValue.delete_project_on_export,
            }
        }] as any;
        if(formValue.phone_number){
            if(formValue.phone_number[0] === '+'){
                request[0].update_info.phone_number = formValue.phone_number;
            }else if(formValue.phone_number[0] === '0'){
                request[0].update_info.phone_number = "+81" + formValue.phone_number.substring(1);
            }else{
                request[0].update_info.phone_number = "+" + formValue.phone_number;
            }
        }else{
            request[0].update_info.phone_number = formValue.phone_number;
        }
        return request;
    }

    // Submitイベント
    const handleEditSubmit = (value: any) => {
        const request = createRequestData(value);
        setLoadingFlag(true);
        onUpdateUsers(request);
    }
    // キャンセル
    const handleCancel = () => {
        navigate(-1);
    };

    const handleSuccess = (data:any) => {
        if (data && data.result_code == 0) {
            onUpdateSuccess();
            updateUserInfo({...user, deleteProjectOnExport: getValues("delete_project_on_export")})
        }else{
            onUpdateError();
        }
        setLoadingFlag(false);
    }

    const handleError = (error:any) => {
        onUpdateError();
        setLoadingFlag(false);
    }

    return <>
        {loadingFlag && (
            <div style={{ top: "0px", left: '0px', position: "fixed", zIndex: 1500, width: '100%', height: '100%', padding: '50vh 50% 50% 50%', background: '#00000030' }}>
                <CircularProgress/>
            </div>
        )}
        <form onSubmit={handleSubmit(handleEditSubmit)}>
            <Stack justifyContent="center" alignItems="center" style={{ height: '66vh'}}>
                <Typography width="60%" variant="h4" mb={1}>ユーザー設定</Typography>
                <div style={{ height: '60%', width: '60%' }}>
                    <Stack mt="5px" mb="3px" width="100%" spacing={2}>
                        <Stack>
                            <Grid container direction="row">
                                <Controller
                                    control={control}
                                    name="custom_company_name"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="会社名"
                                            size="small"
                                            inputProps={{
                                                readOnly:true,
                                            }}
                                            sx={{bgcolor:"floralwhite"}}
                                            error={!!errors?.custom_company_name}
                                            helperText={errors?.custom_company_name?.message}
                                            InputProps={{ sx: { borderRadius: "10px" } }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack> 
                            <Grid container direction="row">
                                <Grid item xs={5.7}>
                                    <Controller
                                        control={control}
                                        name="family_name"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="ユーザー名(姓)"
                                                size="small"
                                                inputProps={{
                                                    readOnly:true,
                                                }}
                                                sx={{bgcolor:"floralwhite"}}
                                                error={!!errors?.family_name}
                                                helperText={errors?.family_name?.message}
                                                InputProps={{ sx: { borderRadius: "10px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={0.6}></Grid>
                                <Grid item xs={5.7}>
                                    <Controller
                                        control={control}
                                        name="given_name"
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                fullWidth
                                                type="text"
                                                label="ユーザー名(名)"
                                                size="small"
                                                inputProps={{
                                                    readOnly:true,
                                                }}
                                                sx={{bgcolor:"floralwhite"}}
                                                error={!!errors?.given_name}
                                                helperText={errors?.given_name?.message}
                                                InputProps={{ sx: { borderRadius: "10px" } }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                        <Stack>
                            <Grid container direction="row">
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="メールアドレス"
                                            inputProps={{
                                                maxLength: 255,
                                                readOnly:true,
                                            }}
                                            size="small"
                                            sx={{bgcolor:"floralwhite"}}
                                            InputProps={{ sx: { borderRadius: "10px" } }}
                                            error={!!errors?.email}
                                            helperText={errors?.email?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack>
                            <Grid container direction="row">
                                <Controller
                                    control={control}
                                    name="phone_number"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            type="text"
                                            label="電話番号"
                                            InputProps={{ sx: { borderRadius: "10px" } }}
                                            onChange={(event) => {
                                                field.onChange(event);
                                                if(event.target.value){
                                                    setIsTypePhone(true);
                                                    // setValue("custom_mfa_system","sms");
                                                }else{
                                                    setIsTypePhone(false);
                                                    setValue("custom_mfa_system","email");
                                                }
                                            }}
                                            size="small"
                                            error={!!errors?.phone_number}
                                            helperText={errors?.phone_number?.message}
                                        />
                                    )}
                                />
                            </Grid>
                        </Stack>
                        <Stack>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name='delete_project_on_export'
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                disabled={user?.expired}
                                                size="small"
                                                style={{ padding: '4px 4px 4px 8px' }}
                                                checked={field.value}
                                                onChange={(e) => setValue('delete_project_on_export', e.target.checked)}
                                            />
                                        )}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        color={user?.expired ? 'grey':'black'}
                                    >エクスポートする際にプロジェクトを削除する
                                    </Typography>
                                }
                            />
                        </Stack>
                        {
                            !isRoleAdmin
                            &&
                            <>
                                <Stack>
                                    <Typography marginTop={"-3px"}>パック</Typography>
                                    <Controller
                                        control={control}
                                        name="custom_plan"
                                        render={({ field: { value } }) => (
                                            <ToggleButtonGroup
                                                color="info"
                                                value={value}
                                                exclusive
                                                disabled
                                                aria-label="Platform"
                                                size="small"
                                                aria-labelledby="権限"
                                            >
                                                <ToggleButton value={"lp"} sx={{ borderRadius: "10px" }}>Ligth Pack</ToggleButton>
                                                <ToggleButton value={"sp"} sx={{ borderRadius: "10px" }}>Service Pack</ToggleButton>
                                                <ToggleButton value={"pp"} sx={{ borderRadius: "10px" }}>PM Pack</ToggleButton>
                                            </ToggleButtonGroup>
                                        )}
                                    />
                                </Stack>
                                <Stack mt={1}>
                                    <Grid container direction="row">
                                        <FormControl size='medium' fullWidth>
                                            <Controller
                                                control={control}
                                                name="custom_expiration_start_date"
                                                rules={{ required: '必須項目です。選択してください。' }}
                                                render={({ field: { value } }) => (
                                                    <>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DateTimePicker
                                                                format="YYYY/MM/DD"
                                                                views={['year', 'month', 'day']}
                                                                openTo="day"
                                                                label="有効期限（開始日）"
                                                                readOnly={true}
                                                                value={startDateSelect}
                                                                
                                                                slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', error: !!errors?.custom_expiration_start_date, sx: { backgroundColor: "floralwhite" } } }}
                                                            />
                                                        </LocalizationProvider>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {
                                        !!errors?.custom_expiration_start_date &&
                                        <FormHelperText style={{ color: 'red' }}>{errors?.custom_expiration_start_date?.message as any}
                                        </FormHelperText>
                                    }
                                </Stack>
                                <Stack mt={1}>
                                    <Grid container direction="row">
                                        <FormControl size='medium' fullWidth>
                                            <Controller
                                                control={control}
                                                name="custom_expiration_end_date"
                                                rules={{ required: '必須項目です。選択してください。' }}
                                                render={({ field: { value } }) => (
                                                    <>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DateTimePicker
                                                                format="YYYY/MM/DD"
                                                                views={['year', 'month', 'day']}
                                                                openTo="day"
                                                                label="有効期限（終了日）"
                                                                readOnly={true}
                                                                value={endDateSelect}
                                                                onChange={(newValue: any) => {
                                                                    if (newValue != null) {
                                                                        if (newValue.$d.toString() === "Invalid Date") {
                                                                            setError("custom_expiration_end_date", {
                                                                                message: "必須項目です。選択してください。",
                                                                                type: "required"
                                                                            })
                                                                            setValue("custom_expiration_end_date", "")
                                                                        } else {
                                                                            const month = Number(newValue.$M) + 1;
                                                                            const date = newValue.$y + "/" + (month < 10 ? "0" + month.toString() : month.toString()) + "/" + (Number(newValue.$D) < 10 ? "0" + newValue.$D.toString() : newValue.$D.toString())
                                                                            setValue("custom_expiration_end_date", date)
                                                                        }
                                                                    }
                                                                    if (newValue == null) {
                                                                        setError("custom_expiration_end_date", {
                                                                            message: "必須項目です。選択してください。",
                                                                            type: "required"
                                                                        })
                                                                        setValue("custom_expiration_end_date", "")
                                                                    }
                                                                }}
                                                                slotProps={{ actionBar: { actions: [] }, textField: { size: 'small', error: !!errors?.custom_expiration_end_date, sx: { backgroundColor: "floralwhite" } } }}
                                                            />
                                                        </LocalizationProvider>
                                                    </>
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    {
                                        !!errors?.custom_expiration_end_date &&
                                        <FormHelperText style={{ color: 'red' }}>{errors?.custom_expiration_end_date?.message as any}
                                        </FormHelperText>
                                    }
                                </Stack>
                            </>
                        }
                        <Stack>
                            <Grid container direction="row">
                                {
                                    isShowMFAMethod  &&
                                    <Grid item xs={5.7}>
                                        <fieldset style={{border:"1px solid #D3D3D3"}}>
                                            <legend>多要素認証（MFA）方式</legend>
                                            <Controller
                                                name="custom_mfa_system"
                                                control={control}
                                                render={({ field }) => (
                                                    <RadioGroup {...field}>
                                                        {
                                                            isTypePhone &&
                                                            <FormControlLabel 
                                                                value='sms'
                                                                control={<Radio size='small'/>}
                                                                label={<Typography variant="body2" style={{color:"grey"}}>SMS</Typography>}
                                                            />
                                                        }
                                                        <FormControlLabel
                                                            value='email'
                                                            control={<Radio size='small'/>}
                                                            label={<Typography variant="body2" style={{color:"grey"}}>Eメ一ル</Typography>}
                                                        />
                                                    </RadioGroup>
                                                )}
                                            />
                                        </fieldset>
                                    </Grid>
                                }
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack direction='row' justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
                        <Button variant="contained" type="submit" sx={{ borderRadius: "10px" }}>保存</Button>
                        <Button color="inherit" variant="contained" sx={{ borderRadius: "10px" }} onClick={handleCancel}>キャンセル</Button>
                    </Stack>
                    
                </div>
            </Stack>
        </form>
        <FetchHelper
            fetchId={UPDATE_USER}
            onComplete={(success:any, data:any, error:any) =>
                success ? handleSuccess(data) : handleError(error)}
        />
        <ToastContainer />
    </>
}
const mapStateToProps = (state: ApplicationState) => ({
    user: state.app.user,
    attribute: state.app.user,
})
const mapDispatchToProps = (dispatch: any) => ({
    onUpdateUsers: (data:any) => dispatch(actionCreators.fetch(UPDATE_USER,"/user/edit-user","POST",data,false,true)),
    updateUserInfo: (data: any) => dispatch(updateUserInfo(data)),
    onUpdateSuccess: () =>
        dispatch(actionCreators.showMessage({
            type: "info",
            title: "ユーザー情報編集",
            body: "ユーザー情報を更新しました。",
        })),
    onUpdateError: () =>
        dispatch(actionCreators.showMessage({
            type: "error",
            title: "ユーザー情報編集",
            body: "ユーザー情報が更新出来ませんでした。",
        })),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSetting as any);
