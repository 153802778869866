import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

export type AlertDialogProps = {
  title: string;
  message: string;
  isOpen: boolean;
  onOK: () => void;
  onCancel?: () => void;
};

export const AlertDialog = (props: AlertDialogProps) => {
  const { title, message, isOpen, onOK, onCancel } = props;
  const [open, setOpen] = React.useState(isOpen);

  const handleOK = () => {
    console.log(">>> modal handleOK")
    setOpen(false);
    onOK();
  };

  const handleCancel = () => {
    console.log(">>> modal handleCancel")
    setOpen(false);
    {onCancel && onCancel()};
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onKeyDown={(e) => { e.preventDefault(); e.key === "Enter" && handleOK()}}
      >
        <DialogTitle id="alert-dialog-title" style={{fontWeight:"bold"}}>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography whiteSpace={'pre-wrap'}>
              {message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOK} variant="contained">はい</Button>
          {onCancel && <Button onClick={handleCancel} color="inherit" variant="contained">いいえ</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;